import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageValidatorService {

  constructor() {
  }

  async validateImageSize(image, type) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const url = window.URL;
      img.onload = () => {
        switch (type) {
          case 'image-selection':
          case 'issue-comparison':
            if (img.width === img.height) {
              resolve(img);
            } else {
              reject();
            }
            break;
          default:
            reject();
            break;
        }

      };
      img.onerror = reject;
      img.src = url.createObjectURL(image);
    });
  }
}
