import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeStringPipe'
})
export class CapitalizeStringPipe implements PipeTransform {

  transform(value: string, firstLetter: boolean, everyWord: boolean = false): any {
    if ( !firstLetter ) {
      return value.toUpperCase();
    }
    if ( everyWord ) {
      return value.split(" ").map(word => {
        return word[0].toUpperCase() + value.slice(1);
      }).join(" ");
    } else {
      return value[0].toUpperCase() + value.slice(1);
    }
  }

}
