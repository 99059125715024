<div class="input-group md-form form-sm form-1 pl-0 position-relative">
  <div class="input-group-prepend">
    <span class="input-group-text search-icon">
      <i class="fas fa-search" aria-hidden="true"></i>
    </span>
  </div>
  <input
    #filter
    class="form-control my-0 py-1 product-search-input shadow-none"
    type="text"
    aria-label="Search"
    [(ngModel)]="searchTerm"
    (keyup)="onSearch()">
  <div class="search-by-type-holder">
    <select
      class="form-control shadow-none"
      [(ngModel)]="chosenSearchBy"
      (change)="changeSearchBy()">
      <option
        *ngFor="let searchOption of searchByKeys"
        [value]="searchOption">
        {{searchOption}}
      </option>
    </select>
  </div>
  <div class="input-group-append search-clear-holder" *ngIf="searchTerm">
    <span class="input-group-text search-clear">
      <button
        mat-icon-button
        class="remove-btn"
        (click)="clearSearch()">
        <span><mat-icon>clear</mat-icon></span>
      </button>
    </span>
  </div>
</div>
