import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { ImageValidationService } from '../../../../shared/services/image-validation.service';
import { NotificationsService } from '../../../../shared/services/notifications.service';
import { NotificationBuilderService } from '../../../../shared/services/notification-builder.service';
import { TriageService } from '../../../../shared/services/triage-service.service';
import { ProductsService } from '../../../../shared/services/products.service';
import {cloneDeep, isNumber, sortBy} from 'lodash';

@Component({
  selector: 'app-sidebar-custom-outcome',
  templateUrl: './sidebar-custom-outcome.component.html',
  styleUrls: ['./sidebar-custom-outcome.component.css']
})
export class SidebarCustomOutcomeComponent implements OnInit {

  @Input() data: any;
  contentDataTypes: any;
  editedModule: any;
  editingModule: boolean;
  editedModuleIndex: number;
  outcomeContent: any;
  hasCarousel: boolean;
  carouselValid: boolean;
  carouselValidation: any;
  availableAlgorithms: any;
  filteredAlgorithms: any;
  loadingAvailableAlgorithms: boolean;
  availableCTAProducts: any;
  ctaChipsInput: any;
  filteredCTAProducts: any;
  loadingAvailableCTAProducts: boolean;
  ctaChosenProducts: any;
  availableProducts: any;
  filteredProducts: any;
  showButtonExternalLink: boolean;
  loadingAvailableProducts: boolean;
  loadingInitialProducts: boolean;
  showCustomNotificationPicker: boolean;
  showPreview: boolean;

  constructor(private dialog: MatDialog,
              private sanitizer: DomSanitizer,
              private authService: AuthenticationService,
              private alertService: AlertService,
              private imageValidationService: ImageValidationService,
              private notificationsService: NotificationsService,
              private notificationBuilderService: NotificationBuilderService,
              private triageService: TriageService,
              private productsService: ProductsService) { }

  ngOnInit() {
    if (this.data && this.data.data.content.pages?.length > 0) {
      this.outcomeContent = this.data.data.content.pages[0].content;
      this.getBuilderOptions();
      this.handleOutcomeContent(this.outcomeContent);
      this.setImageValidation();
    } else {
      this.alertService.error('Problem parsing custom content');
    }
  }

  handleOutcomeContent(data) {
    data.forEach(content => {
      if (content.type === 'video') {
        content.tempData = {};
        content.tempData.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(content.content);
      }
      if (content.type === 'product') {
        this.getContentProduct(content);
        if (content && !content.hasOwnProperty('custom_summary')) {
          content.custom_summary = null;
        }
        content.tempData = {};
        content.tempData.hasProductTextOverride =
          typeof content.custom_summary === 'string' && content.custom_summary.length > 0 || false;
      }
      if (content.type === 'carousel') {
        this.hasCarousel = true;
        content.content = content.content.map((x, i) => {
          x.index = i;
          return x;
        });
        content.tempData = {};
        content.tempData.selectedCarouselOption = content.content[0];
      }
    });
    this.carouselValid = this.notificationBuilderService.validateCarousel(data);
  }

  handleEditModule(event) {
    // this.editedModule = module;
    this.showCustomNotificationPicker = false;
    this.editingModule = true;
    this.editedModule = event.module;
    this.editedModuleIndex = event.index;
    // Sanitize Video URL if type video and no tempData
    if (this.editedModule.type === 'video' && !this.editedModule.tempData) {
      this.editedModule.tempData = {};
      this.editedModule.tempData.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.editedModule.content);
    }
    // If type call-to-action reset inputs
    if (this.editedModule.type === 'call-to-action') {
      this.resetCTAInputs();
    }
    // If type product get all products
    if (this.editedModule.type === 'product') {
      if (this.editedModule.content === this.contentDataTypes[5].content) {
        this.editedModule.content.id = null;
      }
      this.getAllModuleProducts();
      this.editedModule.tempData = {
        hasProductTextOverride: typeof this.editedModule.custom_summary === 'string'
      };
    }
    if (this.editedModule.type === 'carousel' && !this.editedModule.tempData) {
      if (!this.editedModule.content || !this.editedModule.content.length) {
        this.editedModule.content = [];
      }
      this.editedModule.tempData = {
        selectedCarouselOption: null
      };
    }
  }

  getBuilderOptions() {
    this.contentDataTypes = this.notificationsService.getNotificationDataTypes();
  }

  openCustomBuilder(template) {
    this.dialog.open(template, {
      width: '90vw',
      height: '80vh',
      autoFocus: false
    });
  }

  openCustomPreview(template) {
    this.showPreview = true;
    const previewDialog = this.dialog.open(template, {
      width: '90vw',
      height: '80vh',
      autoFocus: false,
    });
    previewDialog.afterClosed().subscribe(data => {
      this.showPreview = false;
    });
  }

  dropModule(event) {
    // If move from same container then shift in notificationContent
    if (event.previousContainer === event.container) {
      moveItemInArray(this.outcomeContent, event.previousIndex, event.currentIndex);
    } else {
      // Else push to notificationContent
      let newContent;
      switch (event.item.element.nativeElement.id) {
        case this.contentDataTypes[0].type:
          newContent = this.contentDataTypes[0];
          break;
        case this.contentDataTypes[1].type:
          newContent = this.contentDataTypes[1];
          break;
        case this.contentDataTypes[2].type:
          newContent = this.contentDataTypes[2];
          break;
        case this.contentDataTypes[3].type:
          newContent = this.contentDataTypes[3];
          break;
        case this.contentDataTypes[4].type:
          newContent = JSON.parse(JSON.stringify(this.contentDataTypes[4]));
          break;
        case this.contentDataTypes[5].type:
          newContent = cloneDeep(this.contentDataTypes[5]);
          break;
        case this.contentDataTypes[6].type:
          newContent = this.contentDataTypes[6];
          break;
        case this.contentDataTypes[7].type:
          if (this.outcomeContent.find((x) => x.type === 'carousel') !== undefined) {
            newContent = undefined;
            this.alertService.error(
              'A carousel has already been added to this notification. Please remove the existing item before ' +
              'adding a new one.'
            );
          } else {
            newContent = this.contentDataTypes[7];
            this.hasCarousel = true;
            this.carouselValid = false;
          }
          break;
      }
      if (!newContent) {
        return;
      }
      if (this.outcomeContent.length === 0) {
        this.outcomeContent.push({...newContent});
        return false;
      }
      this.outcomeContent.splice(event.currentIndex, 0, {...newContent});
    }
  }

  handleCTAChange(event) {
    switch (event) {
      case 'symptom-checker':
        this.editedModule.content.triage_id = '';
        delete this.editedModule.content.link;
        delete this.editedModule.content.product_ids;
        this.getAllAlgorithms();
        break;
      case 'buy-products':
        delete this.editedModule.content.link;
        delete this.editedModule.content.triage_id;
        this.getAllCTAProducts();
        break;
      case 'external-link':
        this.showButtonExternalLink = true;
        this.editedModule.content.link = {};
        this.editedModule.content.link.web_view = false;
        delete this.editedModule.content.triage_id;
        delete this.editedModule.content.product_ids;
        break;
      default:
        delete this.editedModule.content.triage_id;
        delete this.editedModule.content.product_ids;
        delete this.editedModule.content.link;
        break;
    }
    this.resetCTAInputs();
  }

  getAllAlgorithms() {
    this.loadingAvailableAlgorithms = true;
    if (this.availableAlgorithms) {
      this.loadingAvailableAlgorithms = false;
      this.filteredAlgorithms = this.availableAlgorithms;
      return false;
    }
    this.triageService.listTriages().subscribe((data: any) => {
      this.loadingAvailableAlgorithms = false;
      this.availableAlgorithms = data.trees;
      this.filteredAlgorithms = this.availableAlgorithms;
    }, data => {
      this.loadingAvailableAlgorithms = false;
      this.alertService.error(data.error);
    });
  }

  getAllCTAProducts() {
    this.editedModule.content.product_ids = [];
    this.availableCTAProducts = [];
    this.loadingAvailableCTAProducts = true;
    this.outcomeContent.forEach((module: any) => {
      if (module.type === 'product') {
        if (this.availableCTAProducts.findIndex(i => i.id === module.content.id) === -1) {
          this.availableCTAProducts.push(module.content);
        }
      }
    });
    this.filteredCTAProducts = this.availableCTAProducts;
    this.loadingAvailableCTAProducts = false;
  }

  getAllModuleProducts() {
    if (!this.loadingAvailableProducts) {
      if (this.availableProducts) {
        this.filteredProducts = this.availableProducts;
        this.loadingAvailableProducts = false;
        this.loadingInitialProducts = false;
        return false;
      }
      if (!this.availableProducts) {
        this.loadingAvailableProducts = true;
        this.authService.getProducts().subscribe((data: any) => {
          this.availableProducts = sortBy(data?.products || [], x => x?.name?.toLowerCase()?.trim());
          this.filteredProducts = this.availableProducts;
          this.loadingAvailableProducts = false;
          this.loadingInitialProducts = false;
        }, data => {
          this.loadingAvailableProducts = false;
          this.loadingInitialProducts = false;
          this.alertService.error(data.error);
        });
      }
    }
  }
  getContentProduct(productContent: any) {
    let productId;
    if (isNumber(productContent.content)) {
      productId = productContent.content;
    }
    if (productContent && productContent.content && productContent.content.hasOwnProperty('id')) {
      productId = productContent.content.id;
    }
    if (!productId) {
      // Set Default content
      productContent.content = {...this.contentDataTypes[5].content};
      return false;
    }
    this.loadingAvailableProducts = true;
    this.productsService.getProductByID(productId).subscribe((data: any) => {
      productContent.content = data.product;
      this.loadingAvailableProducts = false;
    }, data => this.alertService.error(data.error));
  }
  setCTAProductChoices() {
    this.ctaChosenProducts = [];
    if (this.editedModule.content.product_ids) {
      this.editedModule.content.product_ids.forEach(product => {
        this.availableProducts.forEach(item => {
          if (item.id === product) {
            this.ctaChosenProducts.push(item);
          }
        });
      });
    }
  }

  resetCTAInputs() {
    if (this.editedModule.content.product_ids) {
      this.availableCTAProducts = [];
      this.outcomeContent.forEach((module: any) => {
        if (module.type === 'product') {
          if (this.availableCTAProducts.findIndex(i => i.id === module.content.id) === -1) {
            this.availableCTAProducts.push(module.content);
          }
        }
        if (module.type === 'call-to-action' && module.content.type === 'buy-products') {
          this.setCTAProductChoices();
        }
      });
      this.filteredCTAProducts = this.availableCTAProducts;
      this.showButtonExternalLink = false;
      this.filteredAlgorithms = null;
      return false;
    }
    if (this.editedModule.content.triage_id || this.editedModule.content.type === 'symptom-checker') {
      if (!this.filteredAlgorithms) {
        if (this.availableAlgorithms) {
          this.filteredAlgorithms = this.availableAlgorithms;
        } else {
          this.getAllAlgorithms();
        }
      }
      this.showButtonExternalLink = false;
      this.filteredCTAProducts = null;
      this.loadingAvailableProducts = null;
      return false;
    }
    if (this.editedModule.content.link || this.editedModule.content.type === 'external-link') {
      this.showButtonExternalLink = true;
      this.filteredAlgorithms = null;
      this.filteredCTAProducts = null;
      return false;
    }
    this.showButtonExternalLink = false;
    this.filteredAlgorithms = null;
    this.filteredCTAProducts = null;
  }

  removeModule(index) {
    this.editingModule = false;
    this.outcomeContent.splice(index, 1);
    this.carouselValid = this.notificationBuilderService.validateCarousel(this.outcomeContent);
  }

  handleCarouselValidation() {
    this.carouselValid = this.notificationBuilderService.validateCarousel(this.outcomeContent);
  }

  setImageValidation() {
    this.carouselValidation = this.imageValidationService.getImageValidationConfig('carousel');
  }

}
