import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-criteria-builder',
  templateUrl: './criteria-builder.component.html',
  styleUrls: ['./criteria-builder.component.css']
})
export class CriteriaBuilderComponent implements OnInit {

  @Input() inputOptions: any;
  @Input() conditionsGroups: Array<any>;
  @Input() isDisabled: boolean;
  @Input() availableConditionsObs: Observable<Array<any>>;
  @Output() updateConditionsGroup: EventEmitter<any> = new EventEmitter<any>();
  @Output() conditionChanged: EventEmitter<any> = new EventEmitter();

  availableConditions: Array<any>;

  constructor() { }

  ngOnInit() {
    this.availableConditionsObs.subscribe((availableConditions) => {
      this.availableConditions = availableConditions;
      this.setAvailableOperators();
    });
  }

  setAvailableOperators() {
    this.conditionsGroups = this.conditionsGroups.map(group => {
      group.conditions = group.conditions.map(condition => {
        const availableOperations = this.availableConditions.filter(avCondition => avCondition.property === condition.property);
        if (availableOperations && availableOperations.length > 0) {
          condition.available_operators = availableOperations[0].operations;
          condition.value_type = availableOperations[0].value_type;
          condition.value_options = availableOperations[0].value_options;
          if (availableOperations[0] && availableOperations[0].results) {
            condition.results = availableOperations[0].results;
          }
          if (availableOperations[0] && availableOperations[0].hasOwnProperty('isLoading')) {
            condition.isLoading = availableOperations[0].isLoading;
          }
        }
        return condition;
      });
      return group;
    });
    this.conditionsGroups = [...this.conditionsGroups];
  }

  updateSelection(condition, type) {
    switch (type) {
      case 'property':
        const matchedProperties = this.availableConditions.filter(avCondition => avCondition.property === condition.property);
        condition.available_operators = matchedProperties[0].operations;
        condition.operations = matchedProperties[0].operations;
        condition.value_type = matchedProperties[0].value_type;
        condition.value_options = matchedProperties[0].value_options;
        condition.operation = matchedProperties[0].operations[0];
        condition.requiresUpdate = matchedProperties[0].value_resolver &&
          (!matchedProperties[0].results || matchedProperties[0].results.length === 0);
        break;
    }
    this.updateConditionsGroup.emit(this.conditionsGroups);
    this.conditionChanged.emit(condition);
  }

  toggleConditionType(condition, type, index) {
    switch (type) {
      case 'and':
        this.conditionsGroups[index].conditions.forEach((item, conIndex) => {
          this.conditionsGroups[index + 1].conditions.splice(conIndex, 0, item);
        });
        this.conditionsGroups.splice(index, 1);
        break;
      case 'or':
        this.conditionsGroups[index].conditions.forEach((item, itemIndex) => {
          if (item === condition) {
            const movedCondition = {
              conditions: [this.conditionsGroups[index].conditions[itemIndex + 1]]
            };
            this.conditionsGroups.push(movedCondition);
            this.conditionsGroups[index].conditions.splice(itemIndex + 1, 1);
          }
        });
        break;
    }
    this.updateConditionsGroup.emit(this.conditionsGroups);
  }

  addCondition() {
    const newCondition = {
      id: this.conditionsGroups.length + 1,
      conditions: [
        {
          property: this.availableConditions[0].property,
          operation: this.availableConditions[0].operations[0],
          available_operators: this.availableConditions[0].operations,
          value_type: this.availableConditions[0].value_type,
          value_options: this.availableConditions[0].value_options,
          value: ''
        }
      ]
    };
    this.conditionsGroups.push(newCondition);
  }

  removeCondition(selectedGroupIndex?: number, selectedConditionIndex?: number) {
    this.conditionsGroups[selectedGroupIndex].conditions = this.conditionsGroups[selectedGroupIndex].conditions
      .filter((condition, index) => {
        return index !== selectedConditionIndex;
      });

    // Remove empty condition groups.
    this.conditionsGroups = this.conditionsGroups.filter((x) => {
      return x.conditions && x.conditions.length > 0;
    });

    this.updateConditionsGroup.emit(this.conditionsGroups);
  }
}
