<span #btnClose class="close" data-dismiss="modal" aria-label="Close" (click)="onNoClick()">
    <span aria-hidden="true">&times;</span>
</span>

<div class="container-fluid total-container">

    <h3 mat-dialog-title class="text-center">
        Import Algorithms Into Category ({{selectedList.length}})
    </h3>

    <div class="container table-container" *ngIf="dataSource">
        <table mat-table [dataSource]="dataSource" class="width-100">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID. </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Title. </th>
                <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>
            <ng-container matColumnDef="species">
                <th mat-header-cell *matHeaderCellDef> Species. </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngFor="let specie of element.species; let index = index">
                        {{specie}}<ng-container
                            *ngIf="element.species.length !== 1 && index !== element.species.length - 1">,
                        </ng-container>
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" (click)="selectRowForImportation(row); $event.stopPropagation()" [ngClass]="{'selected': checkIdAgainstSelected(row)}"></tr>

        </table>
    </div>
    <br>
    <div class="text-center width-100">
        <button class="vet-ai-button small-button" (click)="importAlgorithmsIntoCategory(selectedList)" *ngIf="data.add"> Add Algorithms
        </button>
        <button class="vet-ai-button small-button" (click)="removeAlgorithmsFromCategory(selectedList)" *ngIf="!data.add"> Remove Algorithms
        </button>

    </div>
</div>
