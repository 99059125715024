import {Component, Input, OnInit} from '@angular/core';
import {TriageService} from '../../../../shared/services/triage-service.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Next} from '../../../../shared/models/interface';
import {SidebarComponentInterface} from '../sidebar-item.component';
import {NodeStyleService} from '../../../../shared/services/node-style.service';
import {ImageValidatorService} from '../../../../shared/services/image-validator.service';
import {AlertService} from '../../../../shared/services/alert.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-sidebar-slider',
  templateUrl: './sidebar-slider.component.html',
  styleUrls: ['./sidebar-slider.component.css']
})
export class SidebarSliderComponent implements OnInit, SidebarComponentInterface {
  @Input() public data: any;
  uploadedFile: any;
  styleOptions: Array<string>;
  algorithmPublishedStatus = 'Published';
  algorithmPausedStatus = 'paused';
  imageValidationType = 'image-selection';

  constructor(private nodeStyleService: NodeStyleService, private imageValidatorService: ImageValidatorService, private triageService: TriageService, private alertService: AlertService) {
  }

  ngOnInit() {
    this.styleOptions = this.nodeStyleService.getStyleOptions();
  }

  drop(event: CdkDragDrop<any[]>, node) {

    node.data.options[event.currentIndex].order = event.previousIndex;
    node.data.options[event.previousIndex].order = event.currentIndex;
    moveItemInArray(node.data.options, event.previousIndex, event.currentIndex);
  }


  alertDrag(item, event) {

  }

  async fileChangeEvent(event: any, child): Promise<void> {
    await this.imageValidatorService.validateImageSize(event.target.files[0], this.imageValidationType).then((checked) => {
      this.uploadedFile = event.target.files[0];
      const formData = new FormData();
      formData.append('image', this.uploadedFile, this.uploadedFile.name);
      this.triageService.uploadImage(formData).subscribe((data: any) => {
        child.media_url = environment.imageStoragePrefix + data.filenames[0];
      });
    }, error => {
      this.alertService.error('Image must be 1:1 ratio');
    });
  }

  addOption(node) {
    if (this.canEditAlgorithm()) {
      const option = {
        display_value: '',
        description: '',
        popover: '',
        value: 0,
        order: 0,
        url: 'https://www.henryschein.co.uk/',
        image_url: 'https://www.henryschein.co.uk/',
        media_url: '',
        price: 0,
        style: 'primary'
      };

      if (node.data.options === null || node.data.options === undefined) {
        node.data.options = [];
      }
      node.data.options.push(option);
    }
  }

  removeOption(selected: Next, node) {
    if (this.canEditAlgorithm()) {
      for (let i = 0; i < node.data.options.length; i++) {
        if (selected === node.data.options[i]) {
          node.data.options.splice(i, 1);
        }
      }
    }
  }

  removeImage(child) {
    if (this.canEditAlgorithm()) {
      child.media_url = null;
    }
  }

  private canEditAlgorithm() {
    return this.data.selectedAlgorithm.status !== this.algorithmPublishedStatus &&
      this.data.selectedAlgorithm.status !== this.algorithmPausedStatus;
  }
}
