<section class="image-manager-holder">
  <div class="chosen-img-holder">
    <img *ngIf="chosenImage" [src]="chosenImage" class="chosen-img" />
  </div>
  <div class="btn-holder">
    <button
      mat-stroked-button
      color="success"
      type="button"
      (click)="openImageGallery(mediaGallery)">Pick Image</button>
    <button
      mat-stroked-button
      color="primary"
      type="button"
      (click)="fileInput.click()">Upload Image</button>
    <input
      type="file"
      hidden
      #fileInput
      (change)="uploadImage($event)" />
  </div>
</section>
<ng-template #mediaGallery>
  <section class="container-fluid gallery-holder">
    <article *ngIf="availableImages" class="row">
      <header
        class="col-12"
        *ngIf="availableImages.length > maxDisplayableImages">
        <nav>
          <button
            mat-icon-button
            *ngIf="galleryPage > 1"
            (click)="moveGalleryPage('left')">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <span class="page-title">
            Page {{galleryPage}} of {{availableImages.length / maxDisplayableImages | number: '1.0-0'}}
          </span>
          <button
            mat-icon-button
            *ngIf="(galleryPage * maxDisplayableImages) < availableImages.length"
            (click)="moveGalleryPage('right')">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </nav>
      </header>
      <div
        *ngFor="let image of displayedImages"
        class="col-3 gallery-column"
        [class.disabled]="!image.valid"
        (click)="selectGalleryImage(image)">
        <div
          class="image-holder"
          [class.chosen-image]="image.src === chosenImage">
          <img [src]="image.src" />
          <div class="overlay" *ngIf="!image.valid">
            <div class="overlay-info">
              <mat-icon [matTooltip]="getValidationTooltip()">warning</mat-icon>
              <p>{{image?.width}}px x {{image?.height}}px</p>
            </div>
          </div>

        </div>
      </div>
      <div *ngIf="!displayedImages">
        <p>There are no images in this bucket</p>
      </div>
    </article>
    <article *ngIf="!availableImages" class="row">
      <div class="col-12 text-center loading-holder">
        <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
        <p>Loading images...</p>
      </div>
    </article>
  </section>
</ng-template>
