import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
// Interfaces/Enums
import { ProductInterface } from '../interfaces/product.interface';
import { ProductGroupInterface } from '../interfaces/product-group.interface';
import { ProductLegalCategoryEnum } from '../enums/product-legal-category.enum';
import { TriageProductInterface } from '../interfaces/triage-product.interface';
import {isNumber} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  updateProduct(product: ProductInterface) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/products/${product.id}`, product, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  updateTriageProduct(product: TriageProductInterface) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/products/${product.id}`, product, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  deleteProduct(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.delete(`${environment.apiPrefix}/v1/products/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  createProduct(product: ProductInterface) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/products`, product,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  createTriageProduct(product: TriageProductInterface) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/products`, product,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  getProductByID(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products/${id}/cms`,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  getProductByDocId(doc_id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products/${doc_id}`,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  getProductGroupByDocId(doc_id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products/groups/${doc_id}`,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  getProductPriceUpdates(): Observable<Array<any>> {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products/price/pending`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    }) as Observable<Array<any>>;
  }

  approveProductUpdate(id: number) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.patch(`${environment.apiPrefix}/v1/products/price/pending/${id}/approve`, null, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    }) as Observable<Array<any>>;
  }

  rejectProductUpdate(id: number) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.patch(`${environment.apiPrefix}/v1/products/price/pending/${id}/reject`, null,  {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    }) as Observable<Array<any>>;
  }

  getTriageProducts() {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products?triage_only=true`,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  toggleProductStockStatus(product: ProductInterface) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.patch(`${environment.apiPrefix}/v1/products/${product.id}/stock?in-stock=${product.availability.available}`, null,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  toggleProductStoreStatus(product: ProductInterface) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.patch(`${environment.apiPrefix}/v1/products/${product.id}/store?in-store=${product.in_store}`, null,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  toggleProductSubscribableStatus(product: ProductInterface) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.patch(`${environment.apiPrefix}/v1/products/${product.id}/subscriptions?subscribable=${product.can_subscribe}`, null,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  getSuppliers(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products/suppliers`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    }) as Observable<Array<string>>;
  }

  getSpecies() {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/species`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  updateProductStatus(id, status) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.patch(`${environment.apiPrefix}/v1/products/${id}/state/${status}`, null, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  createExistingProductDraft(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/products/${id}/draft`, null, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getProductAdministrationTypes() {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products/types`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getSummariesTree(includeList: Array<'products' | 'groups' | 'variants'> = []): Observable<Array<any>> {
    let params = '';
    includeList.forEach((item,  i) => {
      if (i === 0) {
        params = `?include=${item}`;
      } else {
        params = `${params}&include=${item}`;
      }
    });
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products/categories/summaries${params}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    }) as Observable<Array<any>>;
  }

  returnNewProduct() {
    const newProduct: ProductInterface = {
      id: null,
      doc_id: null,
      name: '',
      description: '',
      summary: '',
      manufacturer: '',
      species: [],
      categories: [],
      active_ingredients: [],
      administration_type: '',
      availability: null,
      efficacy_score: null,
      frequency: null,
      frequency_unit: null,
      status: null,
      sku: null,
      images: [],
      price: {
        price: null,
      },
      legal_category: ProductLegalCategoryEnum.NA,
      supplier: '',
      calculated_values: null,
      supplier_net_price: 0,
      can_change_supplier_net_price: false,
      in_store: false,
      is_sundry: false,
      products: [],
      properties: [],
      price_calculation: {},
      has_calculation: false
    };
    return newProduct;
  }

  returnUnitList() {
    return [
      'pack',
      'bottle',
      'ml',
      'tablet',
      'pipette',
      'vial',
      'sachet',
      'pot',
      'cartridge',
      'pen',
      'container',
      'needle',
      'syringe',
      'tube',
      'inhaler',
      'injection',
      'prescription'
    ];
  }

  returnLegalCategories() {
    return {
      prescription: [
        { display: 'POM-V', value: 'pom-v' },
        { display: 'Schedule', value: 'pom-schedule' },
        { display: 'POM-Human', value: 'pom-human' },
        { display: 'Pharmacy', value: 'pharmacy' },
        { display: 'Clinical Trial', value: 'clinical-trial' }
      ],
      regular: [
        { display: 'NFA-VPS', value: 'nfa-vps' },
        { display: 'AVM-GSL', value: 'avm-gsl' },
        { display: 'SAES', value: 'saes ' },
        { display: 'N/A', value: 'n-a' },
      ]
    };
  }

  returnAllLegalCategories() {
    return [
      { display: 'POM-V', value: 'pom-v' },
      { display: 'Schedule', value: 'pom-schedule' },
      { display: 'POM-Human', value: 'pom-human' },
      { display: 'Pharmacy', value: 'pharmacy' },
      { display: 'Clinical Trial', value: 'clinical-trial' },
      { display: 'NFA-VPS', value: 'nfa-vps' },
      { display: 'AVM-GSL', value: 'avm-gsl' },
      { display: 'SAES', value: 'saes ' },
      { display: 'N/A', value: 'n-a' },
    ];
  }

  returnProductTableColumns(type) {
    let columns = [];
    switch (type) {
      case 'triage':
        columns = ['id', 'name', 'species', 'administration_type', 'categories', 'efficacy_score', 'frequency', 'frequency_unit', 'manufacturer', 'status', 'actions'];
        break;
      default:
        columns = ['id', 'name', 'images', 'description', 'special_instructions', 'prices', 'sku', 'legal_category', 'unit', 'quantity', 'species', 'manufacturer', 'supplier', 'status', 'actions'];
        break;
    }
    return columns;
  }

  returnProductCategories() {
    return ['flea_control', 'worm_control'];
  }

  findDocIDAndMerge(data) {
    const tableItems = [];
    // Loop through data and look for duplicate doc_id (if so merge and delete dupe)
    data.products.forEach((item: any) => {
      const dupeCheck = data.products.filter(product => product.doc_id === item.doc_id);
      let hasLive = false;
      let hasArchive = false;
      let hasDraft = false;
      let liveItem, draftItem;
      if (dupeCheck.length > 1) {
        dupeCheck.forEach(dupe => {
          switch (dupe.status) {
            case 'published':
              hasLive = true;
              liveItem = dupe;
              break;
            case 'draft':
            case 'ready':
              hasDraft = true;
              draftItem = dupe;
              break;
            case 'archived':
              hasArchive = true;
              break;
          }
        });
        if (hasLive) {
          if (hasDraft) {
            liveItem.draft = draftItem;
          }
          const existingCheck = tableItems.filter(product => product.id === liveItem.id);
          if (existingCheck.length < 1) {
            tableItems.push(liveItem);
          }
        }
        if (hasDraft && draftItem && !hasLive) {
          const existingCheck = tableItems.filter(product => product.id === draftItem.id);
          if (existingCheck.length < 1) {
            tableItems.push(draftItem);
          }
        }
      } else {
        tableItems.push(item);
      }
    });
    return {products: tableItems};
  }
}
