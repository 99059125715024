<section class="media-inputs-holder">
  <article *ngIf="!mediaUrl">
    <small>No Media associated</small>
  </article>
  <article>
    <input hidden type="file" #fileInput (change)="uploadFile($event)" />
    <section class="media-form-holder">
      <mat-form-field class="media-type-input">
        <label>Type:</label>
        <mat-select [(ngModel)]="mediaType" [disabled]="disabled">
          <mat-option
            *ngFor="let type of availableMediaTypes"
            [value]="type">
            {{type | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>
    <section *ngIf="!mediaUrl" class="media-btn-holder">
      <button
        class="btn btn-primary joii-icon-btn"
        [disabled]="!mediaType || disabled"
        (click)="fileInput.click()">
        <mat-icon>add</mat-icon>
        <span>Add</span>
      </button>
    </section>
    <section *ngIf="mediaUrl" class="media-btn-holder">
      <button
        class="btn btn-primary joii-icon-btn margin-right-10"
        [disabled]="!mediaType || disabled"
        (click)="fileInput.click()">
        <mat-icon>add</mat-icon>
        <span>Change</span>
      </button>
      <button
        class="btn btn-danger joii-icon-btn"
        [disabled]="disabled"
        (click)="clearFile()">
        <mat-icon>delete</mat-icon>
        <span>Clear</span>
      </button>
    </section>
  </article>
  <article>
    <p *ngIf="mediaType === 'graphic'" class="supported-types">
      <span>Supported file types:&nbsp;</span>
      <span *ngFor="let fileType of supportedImageTypes; let i = index;">
        {{fileType}}<span *ngIf="i !== (supportedImageTypes.length - 1)">,&nbsp;</span>
      </span>
    </p>
    <p *ngIf="mediaType === 'video'" class="supported-types">
      <span>Supported file types:&nbsp;</span>
      <span *ngFor="let fileType of supportedVideoTypes; let i = index;">
        {{fileType}}<span *ngIf="i !== (supportedVideoTypes.length - 1)">,&nbsp;</span>
      </span>
    </p>
    <p *ngIf="mediaType === 'document'" class="supported-types">
      <span>Supported file types:&nbsp;</span>
      <span *ngFor="let fileType of supportedDocTypes; let i = index;">
        {{fileType}}<span *ngIf="i !== (supportedDocTypes.length - 1)">,&nbsp;</span>
      </span>
    </p>
  </article>
</section>
