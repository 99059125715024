<div *ngIf="data.data" class='container margin-bottom-20'>
  <label for="options">Options</label>
    <div cdkDropList cdkDropListData class="example-list margin-bottom-10" (cdkDropListDropped)="drop($event, data)">
        <div *ngFor='let child of data.data.options; let i = index'  class="example-box" cdkDrag  cdkDragLockAxis="y">
          <span *ngIf="child">
              <span class="exitClass">
                  <a (click)='removeOption(child, data)'>&times;</a>
              </span>
              <div class="container">
                  <div>
                      <label for="display_value">Display Value</label>
                      <input
                        type="text"
                        name="display_value"
                        class="form-control width-100"
                        [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                        [(ngModel)]="child.display_value">
                  </div>
                  <div>
                    <label for="value">Value</label>
                    <input
                      type="number"
                      name="value"
                      class="form-control width-100"
                      [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                      [(ngModel)]="child.value">
                  </div>
                  <div>
                    <label for="value">Style</label>
                    <select
                      class="form-control width-100"
                      [(ngModel)]="child.style"
                      [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'">
                      <option *ngFor="let option of styleOptions" [value]="option">{{option}}</option>
                    </select>
                  </div>
                </div>
          </span>
      </div>
      <div *ngIf="!data.data.options || data.data.options.length === 0" class="padding-10">
          Please add an option to this question
      </div>
    </div>
    <div class="text-center margin-bottom-10">
        <button
          type="button"
          class="addOptionBtn"
          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
          (click)='addOption(data)'>Add Option +</button>
    </div>
</div>
