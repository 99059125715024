import { Injectable } from '@angular/core';
import { ShippingLabelCSV } from '../models/interface';

@Injectable({
  providedIn: 'root'
})
export class ShippingLabelsService {

  shippingLabelTableCols: Array<string> = [
    'FullName',
    'AddressLine1',
    'City',
    'Postcode',
    'Weight',
    'PackageSize',
    'ServiceCode'];
  shippingPackageSizeOptions: Array<string> = [
    'small parcel',
    'medium parcel',
    'parcel',
    'letter',
    'large letter'
  ];
  shippingServiceCodes: Array<string> = [
    'SD1',
    'BPL1',
    'BPL2',
    'CRL24',
    'CRL48'
  ];
  tooltips: Array<string> = [
    'Package sizes are as follows (all sizes are max Width, Length, Depth): Small Parcel/Parcel 45cm x 35cm x 16cm, Medium Parcel 61cm x 46cm x 46cm, Letter 24cm x 16.5cm x 5mm, Large letter 35.3cm x 25cm x 2.5cm',
    'Service Code refers to the type of delivery needed, i.e Royal Mail First Class, Royal Mail 24 Hours, etc'
  ];

  constructor() { }

  parseLabelData(data) {
    const labels: Array<ShippingLabelCSV> = [];
    const itemIDs: Array<string> = [];
    data.forEach(item => {
      if (!itemIDs.includes(item.order_id)) {
        itemIDs.push(item.order_id);
        const customerAddress = item.address.split(', ');
        const newShippingLabel: ShippingLabelCSV = {
          FullName: item.customer_name,
          AddressLine1: customerAddress[0],
          City: customerAddress.slice(1, customerAddress.length - 1).join(', '),
          Postcode: customerAddress[customerAddress.length - 1],
          PackageSize: this.shippingPackageSizeOptions[0],
          ServiceCode: this.shippingServiceCodes[1],
          Weight: 0.75,
          AvailablePackageSizes: this.getPackageSizes(this.shippingServiceCodes[1])
        };
        labels.push(newShippingLabel);
      }
    });
    return labels;
  }

  getPackageSizes(serviceCode) {
    let newPackageSizes = [];
    switch (serviceCode) {
      case 'SD1':
        newPackageSizes.push(this.shippingPackageSizeOptions[2]);
        newPackageSizes.push(this.shippingPackageSizeOptions[3]);
        newPackageSizes.push(this.shippingPackageSizeOptions[4]);
        break;
      case 'BPL1':
      case 'BPL2':
        newPackageSizes.push(this.shippingPackageSizeOptions[0]);
        newPackageSizes.push(this.shippingPackageSizeOptions[1]);
        newPackageSizes.push(this.shippingPackageSizeOptions[3]);
        newPackageSizes.push(this.shippingPackageSizeOptions[4]);
        break;
      case 'CRL24':
      case 'CRL48':
        newPackageSizes.push(this.shippingPackageSizeOptions[2]);
        newPackageSizes.push(this.shippingPackageSizeOptions[4]);
        break;
      default:
        newPackageSizes = this.shippingPackageSizeOptions;
        break;
    }
    return newPackageSizes;
  }

  weightCheck(weight, type) {
    const weightCheck = {
      isGood: true,
      message: '',
      maxWeight: 0
    };
    switch (type) {
      case 'letter':
        weightCheck.isGood = weight <= 0.1 && weight !== 0 && weight;
        weightCheck.message = 'Max weight for letter is 100g';
        weightCheck.maxWeight = 0.1;
        break;
      case 'large letter':
        weightCheck.isGood = weight <= 0.75 && weight !== 0 && weight;
        weightCheck.message = 'Max weight for letter is 750g';
        weightCheck.maxWeight = 0.75;
        break;
      case 'parcel':
      case 'small parcel':
        weightCheck.isGood = weight <= 2 && weight !== 0 && weight;
        weightCheck.message = 'Max weight for small parcel/parcel is 2KG';
        weightCheck.maxWeight = 2;
        break;
      case 'medium parcel':
        weightCheck.isGood = weight <= 20 && weight !== 0 && weight;
        weightCheck.message = 'Max weight for medium parcel is 20KG';
        weightCheck.maxWeight = 20;
        break;
    }
    return weightCheck;
  }
}
