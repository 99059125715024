import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Question} from '../models/interface';
import {environment} from '../../../environments/environment';
import {TriageViewModel} from '../../triage/view-models/triage.view-model';
import {DataService} from './data.service';

@Injectable({
  providedIn: 'root'
})
export class TriageService {

  constructor(private http: HttpClient, private dataService: DataService, public triageViewModel: TriageViewModel) {
  }

  /** View Model management */

  setCurrentTree(tree) {
    this.triageViewModel.tree = tree;
  }

  clearCurrentTree() {
    this.triageViewModel.tree = null;
  }

  /** API Calls */

  getSpecies() {
    return this.http.get(`${environment.apiPrefix}/v1/species`);
  }

  newTriage(triage) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/decision-trees`, triage,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  updateTriage(triage) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/decision-trees/${triage.id}`, triage, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  createNewDraft(triageID) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/decision-trees/${triageID}/draft`, {}, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  uploadImage(formData) {
    return this.http.post('https://us-central1-vet-ai.cloudfunctions.net/imageUploader', formData);
  }

  listTriages() {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/decision-trees`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  deleteTriage(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.delete(`${environment.apiPrefix}/v1/decision-trees/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getTree(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/decision-trees/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  newQuestion(question) {
    console.log('generating a new question, ', question);
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/decision-trees/${question.tree_id}/questions`, question, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  saveQuestion(update: Question) {
    console.log(update);
    console.log(JSON.stringify(update));
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/decision-trees/${update.tree_id}/questions/${update.id}`, update, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getDiscounts() {
    // change this endpoint to get the categories rather than the trees just here for a mimic.
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/discounts?use=internal`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  cloneAlgorithm(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/decision-trees/${id}/clone`, {}, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  addDiscount(discount) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/discounts`, discount, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getChildrenFromCategory(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/categories/${id}/children`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getTreeOrCategories(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    const url = `${environment.apiPrefix}/v1/decision-trees` + (id ? `?category_id=${id}` : `/`);
    return this.http.get(url, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });

  }

  getTriageChildrenFromCategory(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/categories/${id}/trees`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  updateDiscount(discount) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/discounts/${discount.id}`, discount, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  deleteDiscount(id) {
    console.log(id);
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.delete(`${environment.apiPrefix}/v1/discounts/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getCategories() {
    // change this endpoint to get the categories rather than the trees just here for a mimic.
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/categories`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getOrphansCategories() {
    // change this endpoint to get the categories rather than the trees just here for a mimic.
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/categories/orphans`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getEunuchCategories() {
    // change this endpoint to get the categories rather than the trees just here for a mimic.
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/categories/eunuchs`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  addCategory(category) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/categories`, category, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getCategoriesForTree(id) {
    console.log(id);
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/decision-trees/${id}/categories`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  associateWithCategory(data) {
    console.log(data);
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/decision-trees/${data.tree_id}/categories`, data, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });

  }

  updateCategory(category) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/categories/${category.id}`, category, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  deleteCategory(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.delete(`${environment.apiPrefix}/v1/categories/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  updateCategoryOrder(id, order) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/categories/${id}/children/order`, order, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }
  updateOrphanCategoryOrder(order) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/categories/orphans/order`, order, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }
  updateTreeOrderWithinCategory(id, order) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/categories/${id}/trees/order`, order, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getAdministrationTypes() {
    return this.http.get(`${environment.apiPrefix}/v1/products/types`);
  }

  updateProductStatus(id, status) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.patch(`${environment.apiPrefix}/v1/products/${id}/state/${status}`, null, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  createExistingProductDraft(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/products/${id}/draft`, null, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }
}
