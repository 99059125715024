import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rating'
})
export class RatingPipe implements PipeTransform {
  roundUpToHalfOrNot(number) {
    return number % 0.5 >= 0.25 ? 1 : 0;
  }
  transform(value: any, ...args: any[]): any {
    let starArray = [];
    if (value) {
      const roundedValue = this.roundUpToHalfOrNot(value.rating);
      for (let i = 0; i < 5; i++) {
        if (i < Math.floor(value.rating)) {
          starArray.push('star');
        } else if (i === Math.floor(value.rating)) {
          if (Math.floor(value / 0.5) === (i * 2) + 1) {
            if (roundedValue === 1) {
              starArray.push('star');
            } else {
              starArray.push('half');
            }
          } else {
            if (roundedValue === 1) {
              starArray.push('half');
            } else {
              starArray.push('none');
            }
          }
        } else {
          starArray.push('none');
        }
      }
    } else {
      starArray = ['none', 'none', 'none', 'none', 'none'];
    }
    return starArray;
  }

}
