import { AlertService } from '../../../../shared/services/alert.service';
import { Component, Input, OnInit } from '@angular/core';
import { SidebarComponentInterface } from '../sidebar-item.component';
import { Next } from '../../../../shared/models/interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { ProductInterface } from '../../../../shared/interfaces/product.interface';

@Component({
  selector: 'app-product-recommendation',
  templateUrl: './product-recommendation.component.html',
  styleUrls: ['./product-recommendation.component.css']
})
export class ProductRecommendationComponent implements SidebarComponentInterface, OnInit {

  @Input() data: any;
  products: ProductInterface[] = [];
  constructor(private authService: AuthenticationService, private alertService: AlertService) {

  }

  ngOnInit() {
    if (this.data.type !== 'product-recommendation') {
      // console.log('this is the correct type');
    }
    this.authService.getProducts().subscribe((data: any) => {
      this.products = data.products;
    });
  }

  alertDrag(item, event) {
    //     if (this.itemDragging === item) {

    //     } else {
    //       this.itemDragging = itesm;
    //       this.dataService.alertDragRequest(item);
    //     }
  }
  drop(event: CdkDragDrop<any[]>, node) {
    node.data.options[event.currentIndex].order = event.previousIndex;
    node.data.options[event.previousIndex].order = event.currentIndex;
    moveItemInArray(node.data.options, event.previousIndex, event.currentIndex);
  }

  copyOption(o: any): any {
    return JSON.parse(JSON.stringify(o));
  }
  updateProduct(child) {
    console.log(child);
    child.product_id = child.product.id;
    child.display_value = child.product.name;
  }
  addOption(node) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      let value = 0;
      if (node.data.options) {
        value = node.data.options.length;
      } else {
        value = 0;
      }
      const option = {
        display_value: '',
        description: '',
        popover: '',
        order: 0,
        price: 0,
        product_id: null,
        value: value
      };
      if (node.data.options === null || node.data.options === undefined) {
        node.data.options = [option];
      } else {
        if (node.data.options === []) {
          node.data.options = [option];
        } else {
          console.log(node.data.options);
          for (let i = 0; i < node.data.options.length; i++) {
            node.data.options[i].order = i;
          }
          node.data.options.push(option);
        }
      }
    }
  }

  removeOption(selected: Next, node) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      for (let i = 0; i < node.data.options.length; i++) {
        if (selected === node.data.options[i]) {
          node.data.options.splice(i, 1);
        }
      }
    }
  }

}
