import { Component, OnInit, Input } from '@angular/core';
import { Next } from '../../../../shared/models/interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SidebarComponentInterface } from '../sidebar-item.component';
import { NodeStyleService } from '../../../../shared/services/node-style.service';

@Component({
  selector: 'app-sidebar-product-selection',
  templateUrl: './sidebar-product-selection.component.html',
  styleUrls: ['./sidebar-product-selection.component.css']
})
export class SidebarProductSelectionComponent implements OnInit, SidebarComponentInterface {
  @Input() public data: any;

  fleaProductData;
  variables = [];
  styleOptions: Array<string>;

  constructor(private nodeStyleService: NodeStyleService) { }

  ngOnInit() {
    this.styleOptions = this.nodeStyleService.getStyleOptions();
    // this.variables = ['product_name', 'manufacturer', 'active_ingredient', 'frequency', 'efficacy_score', 'category'];
    this.fleaProductData = [{
      product_name: 'Advocate Spot On',
      manufacturer: 'Mayer',
      active_ingredient: ['Imidacloprid', 'Moxidectin'],
      frequency: 'Daily',
      efficacy_score: 5,
      category_array: ['Flea_Control']
    },
    {
      product_name: 'Advantix Spot On',
      manufacturer: 'Mayer',
      active_ingredient: ['Imidacloprid', 'Moxidectin'],
      frequency: 'Daily',
      efficacy_score: 3,
      category_array: ['Flea_Control']
    },
    {
      product_name: 'Seresto Collar',
      manufacturer: 'Mayer',
      active_ingredient: ['Flumethrin', 'Imidacloprid'],
      frequency: 'Daily',
      efficacy_score: 5,
      category_array: ['Flea_Control']
    }];
    this.generateVariables(this.fleaProductData);
  }
  generateVariables(array) {
    for (const [key, value] of Object.entries(array[0])) {
      this.variables.push(key);
    }

  }
  drop(event: CdkDragDrop<any[]>, node) {
    node.data.options[event.currentIndex].order = event.previousIndex;
    node.data.options[event.previousIndex].order = event.currentIndex;
    moveItemInArray(node.data.options, event.previousIndex, event.currentIndex);
  }
  addOption(node) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      const option = {
        display_value: '',
        description: '',
        popover: '',
        value: 0,
        order: 0,
        url: '',
        image_url: '',
        price: 0,
        style: 'primary'
      };
      if (node.data.options === null || node.data.options === undefined) {
        node.data.options = [];
      } else {
        node.data.options.push(option);
      }
    }
  }

  removeOption(selected: Next, node) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      for (let i = 0; i < node.data.options.length; i++) {
        if (selected === node.data.options[i]) {
          node.data.options.splice(i, 1);
        }
      }
    }
  }
}
