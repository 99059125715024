<section *ngIf="conditionsGroups">
  <article *ngFor="let conditionGroup of conditionsGroups; let groupIndex = index">
    <div *ngFor="let condition of conditionGroup.conditions; let conditionIndex = index;"
         [class.delete-available]="groupIndex > 0 || conditionIndex > 0"
         class="group-holder">
      <mat-form-field class="condition-select">
        <mat-select (selectionChange)="updateSelection(condition, 'property')" [(ngModel)]="condition.property"
                    [disabled]="isDisabled">
          <mat-option
            *ngFor="let availableProperties of availableConditions"
            [value]="availableProperties.property">
            {{availableProperties.property}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="condition-select">
        <mat-select (selectionChange)="updateSelection(condition, 'operation')" [(ngModel)]="condition.operation"
                    [disabled]="isDisabled">
          <mat-option
            *ngFor="let availableOperations of condition.available_operators"
            [value]="availableOperations">
            {{availableOperations}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container [ngSwitch]="condition.value_type">
        <mat-form-field *ngSwitchCase="'text'" class="condition-select">
          <input
            (ngModelChange)="updateSelection(condition, 'value')"
            *ngSwitchCase="'text'"
            [(ngModel)]="condition.value"
            [disabled]="isDisabled"
            matInput
            type="text"/>
        </mat-form-field>
        <mat-form-field *ngSwitchCase="'number'" class="condition-select">
          <input
            (ngModelChange)="updateSelection(condition, 'value')"
            *ngSwitchCase="'number'"
            [(ngModel)]="condition.value"
            [disabled]="isDisabled"
            matInput
            type="number"/>
        </mat-form-field>
        <ng-container *ngSwitchCase="'select'">
          <mat-form-field *ngIf="condition.property !== 'Breed' && condition.property !== 'Clinical Trial Step' && condition.property !== 'Clinical Trial ID'"
                          class="condition-select">
            <div class="select-container">
              <mat-select
                (selectionChange)="updateSelection(condition, 'value')"
                [(ngModel)]="condition.value"
                [disabled]="isDisabled">
                <mat-option *ngFor="let option of condition.value_options" [value]="option">{{option}}</mat-option>
              </mat-select>
              <div class="mat-spinner">
                <mat-spinner *ngIf="condition?.isLoading" [diameter]="20"></mat-spinner>
              </div>
            </div>
          </mat-form-field>
          <mat-form-field *ngIf="condition.property === 'Clinical Trial Step' || condition.property === 'Clinical Trial ID'"
                          class="condition-select">
            <div class="select-container">
              <mat-select
                (selectionChange)="updateSelection(condition, 'value')"
                [(ngModel)]="condition.value"
                [disabled]="isDisabled">
                <mat-option *ngFor="let option of condition?.results" [value]="option.value">{{option.value}}
                  : {{option.display_value}}</mat-option>
              </mat-select>
            </div>
            <div class="mat-spinner">
              <mat-spinner *ngIf="condition?.isLoading" [diameter]="20"></mat-spinner>
            </div>
          </mat-form-field>
          <mat-form-field *ngIf="condition.property === 'Breed'" class="condition-select">
            <div class="select-container">
              <mat-select
                (selectionChange)="updateSelection(condition, 'value')"
                [(ngModel)]="condition.value"
                [disabled]="condition?.isLoading || isDisabled">
                <mat-option *ngFor="let option of condition?.results" [value]="option.name">{{option.name}}</mat-option>
              </mat-select>
              <div class="mat-spinner">
                <mat-spinner *ngIf="condition?.isLoading" [diameter]="20"></mat-spinner>
              </div>
            </div>
          </mat-form-field>
        </ng-container>
        <mat-form-field *ngSwitchCase="'boolean'" class="condition-select">
          <mat-select
            (selectionChange)="updateSelection(condition, 'value')"
            [(ngModel)]="condition.value"
            [disabled]="isDisabled">
            <mat-option *ngFor="let option of ['true', 'false']" [value]="option">{{option}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <mat-icon (click)="removeCondition(groupIndex, conditionIndex)" *ngIf="groupIndex > 0 || conditionIndex > 0"
                class="remove-condition">delete
      </mat-icon>

      <div *ngIf="conditionIndex !== conditionGroup.conditions.length -1">
        <div
          class="separator-toggle or-active">
          <span (click)="toggleConditionType(condition, 'or', groupIndex)" class="and">And</span>
          <span class="or">Or</span>
        </div>
      </div>
    </div>
    <div *ngIf="groupIndex !== conditionsGroups.length - 1">
      <div class="separator-toggle and-active">
        <span class="and">And</span>
        <span (click)="toggleConditionType(conditionGroup, 'and', groupIndex)" class="or">Or</span>
      </div>
    </div>
  </article>
  <article class="button-holder">
    <button (click)="addCondition()" [disabled]="isDisabled" color="accent" mat-stroked-button type="button">
      <mat-icon>add</mat-icon>
      Add condition
    </button>
  </article>
</section>
