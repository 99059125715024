import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Token} from '../models/interface';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient, private router: Router) {
  }

  private updateLogin: EventEmitter<any> = new EventEmitter();

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.authPrefix}/v1/login`,
      {
        grant_type: 'password',
        client_id: environment.client_id,
        client_secret: environment.client_secret,
        username: username,
        password: password,
        scope: 'admin',
      })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
        }
        return user;
      }));
  }

  loginAlert(data: Token) {
    this.updateLogin.emit(data);
  }

  alertLogin(): EventEmitter<any> {
    return this.updateLogin;
  }

  getUserByEmail(email) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/users/${email}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getToken() {
    //   return this.http.get<any>(`https://authentication.vet-ai.com/v1/tokens/${localStorage.getItem('currentUser')}`);
    return localStorage.getItem('currentUser');
  }

  deleteUser(user) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.delete(`${environment.apiPrefix}/v1/users/${user.id}`, token.access_token.access_token);
  }

  updateUser(user) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/users/${user.id}`, user, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });

  }

  getUsers(limit, offset) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    // return this.http.get(`${environment.apiPrefix}/v1/users/?limit=${limit}&offset=${offset}`, { headers: {
    return this.http.get(`${environment.apiPrefix}/v1/users`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }, params: {limit: limit, offset: offset}
    });
    // }});
  }

  getProducts(){
    console.log('getting products');
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products?exclude_triage=true`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
    // }, params: { limit: limit, offset: offset} });
  }

  getTriageProducts() {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products?triage_only=true`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getSuppliers(): Observable<any>{
    console.log('getting suppliers');
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products/suppliers`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    }) as Observable<Array<string>>;
    // }, params: { limit: limit, offset: offset} });
  }

  getProductByID(id, env) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getProductBySKU(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/products/sku/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });

  }

  updateProduct(product) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/products/${product.id}`, product, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  deleteProduct(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.delete(`${environment.apiPrefix}/v1/products/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  createProduct(product) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/products`, product,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  toggleProductStockStatus(product) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.patch(`${environment.apiPrefix}/v1/products/${product.id}/stock?in-stock=${product.availability.available}`, null,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  getConsultationVideoLink(consultationID) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/consultations/${consultationID}/video/archive`,
      {
        headers: {
          Authorization: 'Bearer ' + token.access_token.access_token
        }
      });
  }

  getMe() {
    return this.http.get(`${environment.authPrefix}/v1/me`);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }

  updateOnlineStatus(data) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    // REMOVE COMMENT
    return this.http.put(`${environment.apiPrefix}/v1/meta/${data.user_id}`, data, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getProfileForStaff(id) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/staff/${id}/profile`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  updatePermission(profile) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/staff/${profile.user_id}/permissions`, {permissions: profile.permissions}, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });

  }

  getStaffProfiles() {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/staff/profiles`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  updateStaffProfile(profile) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/staff/${profile.user_id}/profile`, profile, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  deleteStaffProfile(staff) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.delete(`${environment.apiPrefix}/v1/staff/${staff.user_id}/profile`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });

  }

  createStaffProfile(profile) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/staff/${profile.user_id}/profile`, profile, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  getPermissionsList() {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/permissions`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  createPermission(permission) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/permissions`, `"${permission}"`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  createPermissionGroup(group) {
    console.log('creating a permission group with the value of: ', group);

    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/permission-groups`, `"${group}"`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  deletePermission(id) {
    console.log('deleting a permission with the id of: ', id);

    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.delete(`${environment.apiPrefix}/v1/permissions/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  deletePermissionGroup(id) {
    console.log('deleting a permission group with the id of: ', id);

    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.delete(`${environment.apiPrefix}/v1/permission-groups/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

}
