<section class="star-rating">
  <ng-container *ngFor="let value of rating | rating:rating">
    <span *ngIf="value === 'star'" class="star-container-small">
      <mat-icon class="small star">star</mat-icon>
    </span>
    <span *ngIf="value === 'none'" class="star-container-small">
      <mat-icon class="small star">star_border</mat-icon>
    </span>
    <span *ngIf="value === 'half'" class="star-container-small">
      <mat-icon class="small star">star_half</mat-icon>
    </span>
  </ng-container>
</section>
