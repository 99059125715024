<section *ngIf="availableAppointmentTypes && data">
  <article *ngIf="!data.data.metadata?.appointment_type_id" class="picker-holder">
    <p>Add appointment type</p>
    <app-appointment-type-picker
      [hideClose]="true"
      [appointmentTypes]="availableAppointmentTypes"
      (updateType)="handleAppointmentTypeSelect($event)"></app-appointment-type-picker>
  </article>
  <article *ngIf="data.data.metadata?.appointment_type_id" class="selected-holder">
    <label>Selected appointment type</label>
    <p class="selected-appointment">
      ({{data.data.metadata.appointment_type_id}}) {{data.data.metadata.appointment_type_display}}
      <span class="float-right remove" (click)="clearAppointmentType()"><mat-icon>delete</mat-icon></span>
    </p>
  </article>
</section>
