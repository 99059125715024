import { NgModule } from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
// Material Modules
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSliderModule } from '@angular/material/slider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
// Third Party Modules
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ColorPickerModule } from 'ngx-color-picker';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {ImageModule} from 'primeng/image';
import {AutoCompleteModule} from 'primeng/autocomplete';
// Auth Guard
import { AuthGuard } from './guards/auth-guard';
// Components
import { LdsRollerComponent } from './components/lds-roller/lds-roller.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AlertComponent } from './components/alert/alert.component';
import { ImportAlgorithmComponent } from './components/import-algorithm/import-algorithm.component';
import { SetProductStatusComponent } from './components/set-product-status/set-product-status.component';
import { VersionUpdateDialogComponent } from './components/version-update-dialog/version-update-dialog/version-update-dialog.component';
import { AppointmentTypePickerComponent } from './components/appointment-type-picker/appointment-type-picker.component';
import { CriteriaBuilderComponent } from './components/criteria-builder/criteria-builder.component';
import { ProductsTableComponent } from './components/products-table/products-table.component';
import { NotificationBuilderPreviewComponent } from './components/notification-builder-preview/notification-builder-preview.component';
import { NotificationBuilderEditComponent } from './components/notification-builder-edit/notification-builder-edit.component';
import { NotificationBuilderOptionsComponent } from './components/notification-builder-options/notification-builder-options.component';
import { ImageManagerComponent } from './components/image-manager/image-manager.component';
// Pipes
import { FilterPipe } from './pipes/filter-pipe';
import { CurrencyPipe } from './pipes/currency.pipe';
import { CapitalizeStringPipe } from './pipes/string/capitalize-string.pipe';
import { ShippingServiceCodePipe } from './pipes/shipping-service-code.pipe';
import { BreedFilterPipe } from './pipes/breed-filter.pipe';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { RemoveHyphenPipe } from './pipes/remove-hyphen.pipe';
import { TypeOfPipe } from './pipes/type-of.pipe';
import { RatingPipe } from './pipes/rating.pipe';
import { UrlDataSecurityBypassPipe } from './pipes/url-data-security-bypass.pipe';
// Services
import { TriageService } from './services/triage-service.service';
import { DataService } from './services/data.service';
import { AuthenticationService } from './services/authentication.service';
import { SidebarFormService } from './services/sidebar-form.service';
import { TriageViewModel } from '../triage/view-models/triage.view-model';
import { ShippingLabelsService } from './services/shipping-labels.service';
import { SearchHighlightPipe } from './pipes/search-highlight.pipe';
import { QuestionComponent } from './components/question/question.component';
import { TooltipListFormatPipe } from './pipes/string/tooltip-list-format.pipe';
import { SetCollectionStatusComponent } from './components/set-collection-status/set-collection-status.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { JoiiTableSearchComponent } from './components/joii-table-search/joii-table-search.component';
import { ImageSelectorComponent } from './components/image-selector/image-selector.component';
import { MediaManagerPreviewComponent } from './components/media-manager-preview/media-manager-preview.component';
import { MediaManagerInputComponent } from './components/media-manager-input/media-manager-input.component';
import { TableSearchComponent } from './components/table-search/table-search.component';
import { EdDragAndDropComponent } from './components/ed-drag-and-drop/ed-drag-and-drop.component';

@NgModule({
    declarations: [
        LdsRollerComponent,
        HeaderComponent,
        FooterComponent,
        AlertComponent,
        ImportAlgorithmComponent,
        LimitToPipe,
        FilterPipe,
        CurrencyPipe,
        CapitalizeStringPipe,
        ShippingServiceCodePipe,
        RemoveHyphenPipe,
        BreedFilterPipe,
        VersionUpdateDialogComponent,
        AppointmentTypePickerComponent,
        CriteriaBuilderComponent,
        SetProductStatusComponent,
        ProductsTableComponent,
        SearchHighlightPipe,
        QuestionComponent,
        TooltipListFormatPipe,
        TypeOfPipe,
        RatingPipe,
        UrlDataSecurityBypassPipe,
        SetCollectionStatusComponent,
        NotificationBuilderPreviewComponent,
        NotificationBuilderEditComponent,
        NotificationBuilderOptionsComponent,
        ImageManagerComponent,
        StarRatingComponent,
        JoiiTableSearchComponent,
        ImageSelectorComponent,
        MediaManagerPreviewComponent,
        MediaManagerInputComponent,
        EdDragAndDropComponent,
        TableSearchComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatTableModule,
        DragDropModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatSortModule,
        MatToolbarModule,
        MatButtonModule,
        MatSelectModule,
        MatTabsModule,
        MatMenuModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatSliderModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatButtonToggleModule,
        // Third Party
        PickerModule,
        ColorPickerModule,
        NgxSmartModalModule.forRoot(),
        OverlayPanelModule,
      ImageModule,
      AutoCompleteModule
    ],
  exports: [
    /*-- Modules --*/
    // Core Modules
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatTableModule,
    DragDropModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSortModule,
    MatToolbarModule,
    MatButtonModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSliderModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatRadioModule,
    MatExpansionModule,
    MatButtonToggleModule,
    // Third Party Modules
    PickerModule,
    ColorPickerModule,
    NgxSmartModalModule,
    OverlayPanelModule,
    ImageModule,
    AutoCompleteModule,
    /*-- Components --*/
    LdsRollerComponent,
    HeaderComponent,
    FooterComponent,
    AlertComponent,
    ImportAlgorithmComponent,
    LimitToPipe,
    CurrencyPipe,
    BreedFilterPipe,
    VersionUpdateDialogComponent,
    AppointmentTypePickerComponent,
    CriteriaBuilderComponent,
    SetProductStatusComponent,
    ProductsTableComponent,
    SearchHighlightPipe,
    QuestionComponent,
    TooltipListFormatPipe,
    TypeOfPipe,
    UrlDataSecurityBypassPipe,
    SetCollectionStatusComponent,
    NotificationBuilderPreviewComponent,
    NotificationBuilderEditComponent,
    NotificationBuilderOptionsComponent,
    ImageManagerComponent,
    CapitalizeStringPipe,
    MatSlideToggleModule,
    RatingPipe,
    StarRatingComponent,
    JoiiTableSearchComponent,
    ImageSelectorComponent,
    MediaManagerPreviewComponent,
    MediaManagerInputComponent,
    EdDragAndDropComponent,
    TableSearchComponent
  ],
    providers: [
        AuthGuard,
        TriageService,
        DataService,
        AuthenticationService,
        SidebarFormService,
        TriageViewModel,
        ShippingLabelsService,
        DecimalPipe,
        RatingPipe,
        DatePipe,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ]
})
export class SharedModule { }
