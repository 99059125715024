import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaManagerService {

  constructor(private http: HttpClient) { }

  getAvailableImages() {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(environment.mediaPrefix + '/v1/media/notification-images', {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  uploadImage(image): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(environment.mediaPrefix + '/v1/media/notification-images', image, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  // @ToDo: I have added these calls here as the name of the service matched, might want to refactor/move the above functions
  uploadMediaImage(image): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(environment.mediaPrefix + '/v1/media/images', image, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  uploadMediaVideo(video): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(environment.mediaPrefix + '/v1/media/videos', video, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  uploadMediaDocument(image): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(environment.mediaPrefix + '/v1/media/documents', image, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }
}
