import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../../services/data.service';
import { TriageService } from '../../services/triage-service.service';
import { Algorithm } from '../../models/interface';
@Component({
  selector: 'app-import-algorithm',
  templateUrl: './import-algorithm.component.html',
  styleUrls: ['./import-algorithm.component.css']
})
export class ImportAlgorithmComponent implements OnInit {
  instructionListener: any;
  category_id: number;
  dataSource: MatTableDataSource<Algorithm>;
  displayedColumns: string[] = ['id',  'title', 'species'];
  selectedList: any;
  constructor(public dialogRef: MatDialogRef<ImportAlgorithmComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dataService: DataService, private triageService: TriageService) {
    console.log(data);
    this.setupVariables();
    this.getAlgorithmsForSpecificCategory()
  }

  ngOnInit() {
  }
  setupVariables() {
    this.selectedList = [];
    this.category_id = this.data.category_id
  }
  handleAddCategories(data) {
    console.log(data);
    this.getAlgorithms(data.trees);
    console.log('adding categories');
  }
  handleRemoveCategories(data) {
    console.log(data);
    this.setDataSource(data.trees)
    console.log('removing categories');
  }
  getAlgorithmsForSpecificCategory() {
    this.triageService.getTreeOrCategories(this.category_id).subscribe((triageOrCategory: any) => {
      console.log('this is the data returned for the triage categories', triageOrCategory);
      this.handleSuccessfulReturnFromGettingTreesInSpecificCategory(triageOrCategory);
    }, error => {
      console.log(error);
    })
  }

  handleSuccessfulReturnFromGettingTreesInSpecificCategory(triageOrCategory) {
    if(this.data.add) {
      this.handleAddCategories(triageOrCategory);
    } else {
      this.handleRemoveCategories(triageOrCategory);
    }
  }

  handleTriagesReturned(allTriages, triagesInCategory) {
    console.log(allTriages);
    console.log(triagesInCategory);
    if(triagesInCategory) {
      if(triagesInCategory.length > 0) {
        triagesInCategory.forEach(element => {
          allTriages = this.dataService.removeItemFromArray(allTriages, element);
        });
      }
    }
    console.log(allTriages);
    this.setDataSource(allTriages);
  }

  setDataSource(data) {
    this.dataSource = new MatTableDataSource(data);
  }

  checkIdAgainstSelected(row) {
    let returnValue = false;
    this.selectedList.forEach(element => {
      if(element.id === row.id) {
        returnValue = true;
      }
    });
    return returnValue;
  }

  selectRowForImportation(row) {
    if(this.dataService.checkIfIncludesInArray(this.selectedList, row)) {
      this.selectedList = this.dataService.removeItemFromArray(this.selectedList, row);
    } else {
      this.selectedList.push(row);
    }
  }

  copyObject(data) {
    return JSON.parse(JSON.stringify(data));
  }

  reloadTheData() {
    this.dialogRef.close({data: this.category_id, action: 'checkForTreesAndCategories'});
  }
  removeAlgorithmsFromCategory(list) {
    if(list.length !== 0) {
      let itemToUpdate = list.pop();
      itemToUpdate.parent_id;
      let updateCategoryList = this.updateCategoryList(itemToUpdate);
      let updateCategoryIdList = this.dataService.removeCategoryFromTriage(updateCategoryList, this.category_id);
      const associate = {
        tree_id: itemToUpdate.id,
        category_ids: updateCategoryIdList
      }
      this.associateWithCategoryAndContinue(associate, list);
    } else {
      this.reloadTheData();
    }
  }
  importAlgorithmsIntoCategory(list) {
    if(list.length !== 0) {
      let itemToUpdate = list.pop();
      itemToUpdate.parent_id;
      let categoryIdList = this.updateCategoryList(itemToUpdate);
      categoryIdList.push(this.category_id);
      const associate = {
        tree_id: itemToUpdate.id,
        category_ids: categoryIdList
      }
      this.associateWithCategoryAndContinue(associate, list);
    } else {
      this.reloadTheData();
    }
  }

  updateCategoryList(itemToUpdate) {
    let list = [];
    if(itemToUpdate.category_path.length !== 0) {
      itemToUpdate.category_path.forEach(element => {
        list.push(element[0].id);
      });
    }
    return list;
  }
  handleSuccessfulAssociationWithCategory(list) {
    if(this.data.add) {
      this.importAlgorithmsIntoCategory(list);
    } else {
      this.removeAlgorithmsFromCategory(list);
    }
  }
  associateWithCategoryAndContinue(associate, list) {
    this.triageService.associateWithCategory(associate).subscribe(triage => {
      this.handleSuccessfulAssociationWithCategory(list);
    }, error => {
      console.log(error);
    });
  }

  getAlgorithms(treesWithinCategory) {
    this.triageService.listTriages().subscribe((data: {trees: Algorithm[]}) => {
      console.log(data);
      this.handleTriagesReturned(data.trees, treesWithinCategory);
    }, error => {
      console.log(error);
    });
  }

  onNoClick(): void {
    this.dialogRef.close({data: null, action: null});
  }

}
