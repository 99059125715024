import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageManagerService {

  constructor(private http: HttpClient) { }

  uploadImage(image) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(environment.mediaPrefix + '/v1/media/images', image, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }
}
