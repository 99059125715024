import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchHighlight'
})
export class SearchHighlightPipe implements PipeTransform {

  transform(text: string, searchTerm: string): string {
    if (searchTerm == null || searchTerm == "" || text === '' || text === null || text === undefined) {
      return text;
    }
    const startIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase());
    if (startIndex < 0) {
      return text;
    }
    const endIndex = startIndex + searchTerm.length;
    return text.slice(0, startIndex) + `<mark class="orange">` + text.slice(startIndex, endIndex) + `</mark>` + text.slice(endIndex);
  }

}
