import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentTypesService {

  constructor(private http: HttpClient) { }

  getAppointmentTypes() {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/appointments/types/tree`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  deleteCategory(categoryID) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.delete(`${environment.apiPrefix}/v1/appointments/types/categories/${categoryID}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  updateCategory(category) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/appointments/types/categories/${category.id}`, category, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  createCategory(category) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/appointments/types/categories`, category, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  deleteAppointmentType(appointmentTypeID) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.delete(`${environment.apiPrefix}/v1/appointments/types/${appointmentTypeID}`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  updateAppointmentType(appointmentType) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.apiPrefix}/v1/appointments/types/${appointmentType.id}`, appointmentType, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }

  createAppointmentType(type) {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(`${environment.apiPrefix}/v1/appointments/types`, type, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }
}
