import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NodeStyleService {

  styleOptions: Array<string> = ['primary', 'secondary'];

  constructor() { }

  getStyleOptions() {
    return this.styleOptions;
  }
}
