import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-appointment-type-picker',
  templateUrl: './appointment-type-picker.component.html',
  styleUrls: ['./appointment-type-picker.component.css']
})
export class AppointmentTypePickerComponent implements OnInit {

  @Input() appointmentTypes: any;
  @Input() chosenType: string;
  @Input() hideClose: boolean;
  @Output() updateType: EventEmitter<any> = new EventEmitter<any>();
  @Output() closePicker: EventEmitter<any> = new EventEmitter<any>();
  isSearching: boolean;

  constructor() { }

  ngOnInit() {
    this.setupExpanded();
  }

  handleUpdateType(event) {
    this.updateType.emit(event);
  }

  handleClose() {
    this.closePicker.emit(true);
  }

  setupExpanded() {
    this.appointmentTypes.forEach(item => {
      this.setExpandedClosed(item);
      this.handlePicked(item, this.chosenType);
    });
  }

  setExpandedClosed(category) {
    category.expanded = false;
    category.shouldShow = true;
    if (category.categories) {
      category.categories.forEach(item => {
        this.setExpandedClosed(item);
      });
    }
  }

  handlePicked(category, chosenType) {
    let foundMatchInside = false;
    if (chosenType && category.children) {
      category.children.forEach(child => {
        foundMatchInside = this.handlePicked(child, chosenType) || foundMatchInside;
      });
    }
    if (chosenType && category.appointment_types) {
      category.appointment_types.forEach(type => {
        if (type.name === chosenType) {
          type.isChosen = true;
          foundMatchInside = foundMatchInside || type.isChosen;
        }
      });
    }
    category.category.expanded = foundMatchInside;
    return foundMatchInside;
  }

  toggleCategory(item) {
    item.category.expanded = !item.category.expanded;
  }

}
