<section [ngSwitch]="mediaType" class="media-preview-holder">
  <article *ngSwitchCase="'graphic'" class="image-preview">
    <span *ngIf="!mediaUrl">Missing Image</span>
    <span *ngIf="mediaUrl">
      <img [src]="mediaUrl" />
    </span>
  </article>
  <article *ngSwitchCase="'video'" class="video-preview">
    <span *ngIf="!mediaUrl">Missing Video</span>
    <span *ngIf="mediaUrl" class="width-100">
      <mat-icon>movie</mat-icon>
    </span>
  </article>
  <article *ngSwitchCase="'document'" class="doc-preview">
    <span *ngIf="!mediaUrl">
      Missing Doc
    </span>
    <span *ngIf="mediaUrl" class="width-100">
      <mat-icon>insert_drive_file</mat-icon>
    </span>
  </article>
  <article class="hover-preview" (click)="openPreviewModal(previewModal)">
    <mat-icon>visibility</mat-icon>
  </article>
</section>
<ng-template #previewModal>
  <section [ngSwitch]="mediaType" class="width-100">
    <article *ngSwitchCase="'graphic'" class="width-100">
      <img [src]="mediaUrl" width="100%" />
    </article>
    <article *ngSwitchCase="'video'" class="width-100">
      <video controls width="100%">
        <source [src]="mediaUrl" type="video/mp4">
      </video>
    </article>
  </section>
</ng-template>
