import { Injectable } from '@angular/core';

import { ImageValidationInterface } from '../interfaces/image-validation.interface';

@Injectable({
  providedIn: 'root'
})
export class ImageValidationService {

  validationConfig: ImageValidationInterface;

  constructor() { }

  getImageValidationConfig(type) {
    switch (type) {
      case 'carousel':
      case 'icon':
      default:
        this.validationConfig = {
          minHeight: 1000,
          maxHeight: 2000,
          minWidth: 1000,
          maxWidth: 2000,
          square: true
        };
        break;
    }
    return this.validationConfig;
  }
}
