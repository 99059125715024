import { Component, OnInit, Input } from '@angular/core';
import { typeWithParameters } from '@angular/compiler/src/render3/util';

@Component({
  selector: 'app-sidebar-user-input',
  templateUrl: './sidebar-user-input.component.html',
  styleUrls: ['./sidebar-user-input.component.css']
})
export class SidebarUserInputComponent implements OnInit {

  @Input() public data: any;
  input_types: { display_value: string; value: string; }[];
  constructor() {}

  ngOnInit() {
    this.input_types = [{
      display_value: 'Text',
      value: 'text',
    },
    {
      display_value: 'Weight',
      value: 'weight',
    },
    {
      display_value: 'Age',
      value: 'age',
    }
    ,
    {
      display_value: 'Number',
      value: 'number',
    }];
  }
  setupInputUnitScreen() {
    if (this.data.data.input_type === 'weight') {
      this.data.data.input_unit = 'kg';
    }
    if (this.data.data.input_type === 'age') {
      this.data.data.input_unit = 'months';
    }
  }
}
