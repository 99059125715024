import { Injectable } from '@angular/core';

import { StaffProfile } from '../models/profile';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  staffLoggedIn: StaffProfile;

  constructor() { }

  checkIfStaffsPermissionHasPowerToAction(permission: string) {
    let value = 0;
    switch (permission) {
      case 'admin': {
        value = 5;
        break;
      }
      case 'publish': {
        value = 4;
        break;
      }
      case 'approve': {
        value = 3;
        break;
      }
      case 'write': {
        value = 2;
        break;
      }
      case 'read': {
        value = 1;
        break;
      }
    }
    return value;
  }

  checkIfSpecificLoggedInMemberHasPowerForAction(group: string, permission: string) {
    let returnValue = false;
    this.staffLoggedIn.permissions[group].forEach((element: string) => {
      if (this.checkIfStaffsPermissionHasPowerToAction(element) >= this.checkIfStaffsPermissionHasPowerToAction(permission)) {
        returnValue = true;
      }
    });
    return returnValue;
  }

  checkForAccessPermissionsBasedOnRequirement(group: string, permission: string, user: any) {
    this.staffLoggedIn = user;
    let returnValue = false;
    if (this.staffLoggedIn && this.staffLoggedIn.permissions && this.staffLoggedIn.permissions[group]) {
      if (this.checkIfSpecificLoggedInMemberHasPowerForAction(group, permission)) {
        returnValue = true;
      }
    }
    return returnValue;
  }
}
