// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  suffix: 'dev',
  apiPrefix: 'https://api-dev.vet-ai.com',
  authPrefix: 'https://authentication-dev.vet-ai.com',
  mediaPrefix: 'https://media-dev.vet-ai.com',
  client_id: '428534bd7d469b30',
  client_secret: '175bcc796d55813245732d62e009670eb6907f79123f55fa9413cf8c4421b184',
  x_token: 'fa34fcb8-d2a1-11eb-b8bc-0242ac130003',
  addressApiPrefix: 'https://api.getaddress.io/find/',
  addressApiToken: 'znbu-0y19ECRj4C4wNdjYw17347',
  imageStoragePrefix: 'https://storage.googleapis.com/assets.vet-ai.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
