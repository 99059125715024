import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductInterface } from '../../interfaces/product.interface';

@Component({
  selector: 'app-set-product-status',
  templateUrl: './set-product-status.component.html',
  styleUrls: ['./set-product-status.component.css']
})
export class SetProductStatusComponent implements OnInit {

  @Input() product: ProductInterface | any;
  @Output() setProductStatus: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  setStatus(status: string) {
    this.setProductStatus.emit(status);
  }
}
