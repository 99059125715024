<section class="container">
  <span *ngIf="loadingInitialProducts" class="loading-holder">
    <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
    <span class="loading-message">Loading products</span>
    <p><em>Preview/Edit Disabled</em></p>
  </span>
  <button
    class="btn btn-primary width-100 margin-bottom-10"
    [disabled]="loadingInitialProducts"
    (click)="openCustomPreview(outcomeBuilder)">
    Preview Outcome
  </button>
  <button
    class="btn btn-primary width-100 margin-bottom-10"
    (click)="openCustomBuilder(outcomeBuilder)"
    [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused' || loadingInitialProducts">
    <span *ngIf="outcomeContent.length === 0">Create Outcome</span>
    <span *ngIf="outcomeContent.length > 0">Edit Outcome</span>
  </button>
</section>
<ng-template #outcomeBuilder>
  <section class="container-fluid">
    <div class="row">
      <div class="col-4">
        <article *ngIf="contentDataTypes && !showPreview" class="content-form">
          <app-notification-builder-options [notificationDataTypes]="contentDataTypes"></app-notification-builder-options>
        </article>
      </div>
      <div class="col-4">
        <article *ngIf="contentDataTypes" class="content-preview">
          <app-notification-builder-preview
            [isPreviewMode]="showPreview"
            [notificationDataTypes]="contentDataTypes"
            [notificationContent]="outcomeContent"
            (editModuleOut)="handleEditModule($event)"
            (dropModuleOut)="dropModule($event)"></app-notification-builder-preview>
        </article>
      </div>
      <div class="col-4">
        <article *ngIf="editedModule && editingModule && !showPreview">
          <app-notification-builder-edit
            [editedModule]="editedModule"
            [editedModuleIndex]="editedModuleIndex"
            [ctaChipsInput]="ctaChipsInput"
            [ctaChosenProducts]="ctaChosenProducts"
            [notificationDataTypes]="contentDataTypes"
            [carouselValidation]="carouselValidation"
            [showButtonExternalLink]="showButtonExternalLink"
            [loadingAvailableAlgorithms]="loadingAvailableAlgorithms"
            [loadingAvailableCTAProducts]="loadingAvailableCTAProducts"
            [loadingAvailableProducts]="loadingAvailableProducts"
            [filteredAlgorithms]="filteredAlgorithms"
            [filteredCTAProducts]="filteredCTAProducts"
            [filteredProducts]="filteredProducts"
            [availableAlgorithms]="availableAlgorithms"
            [availableCTAProducts]="availableCTAProducts"
            [availableProducts]="availableProducts"
            (handleCTAChangeEvent)="handleCTAChange($event)"
            (removeModuleEvent)="removeModule($event)"
            (validateCarouselEvent)="handleCarouselValidation()"></app-notification-builder-edit>
        </article>
      </div>
    </div>
  </section>
</ng-template>
