import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormArray } from '@angular/forms';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-ed-drag-and-drop',
  templateUrl: './ed-drag-and-drop.component.html',
  styleUrls: ['./ed-drag-and-drop.component.css']
})
export class EdDragAndDropComponent implements OnInit {
  @Input() selectedForm: FormArray;
  @Input() available: Array<any>;
  @Input() composition = false;
  @Input() type: string;
  @Input() availableTitle = 'Available';
  @Input() selectedTitle = 'Selected';
  @Input() disabled = false;
  @Input() expansionDisabled = false;
  @Output() update: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
  dragging = false;
  searchTerm: string;
  filteredAvailable: Array<any>;

  constructor() {
  }

  ngOnInit() {
    this.filteredAvailable = cloneDeep(this.available);
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    if (event.container.id === 'available-list' && event.previousContainer.id === 'available-list') {
      return;
    }
    const newSelectionList = event.container.id === 'selected-list' ? event.container.data : event.previousContainer.data;
    this.update.emit(newSelectionList);
  }

  checkboxChange(item, event): void {
    this.selectedForm.value.find(s => s.id === item.id).required = event.checked;
    this.update.emit(this.selectedForm.value);
  }

  defaultChange(): void {
    this.update.emit(this.selectedForm.value);
  }

  shouldDisplay(pos: number): boolean {
    return this.composition && pos < this.selectedForm.length - 1 && !this.dragging;
  }

  searchAvailable() {
    this.filteredAvailable = cloneDeep(this.available.filter((item) => item.name.toLowerCase().includes(this.searchTerm)));
  }

  clearSearch() {
    this.searchTerm = '';
    this.searchAvailable();
  }
}
