import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TriageStatusService {

  constructor(private http: HttpClient) { }

  setTriageState(triage) {
    let endpoint: string;
    switch (triage.status) {
      case 'draft':
        endpoint = 'state/draft';
        break;
      case 'in-test':
        endpoint = 'state/in-test';
        break;
      case 'for-approval':
        endpoint = 'state/for-approval';
        break;
      case 'approved':
        endpoint = 'state/approved';
        break;
      case 'published':
        endpoint = 'state/published';
        break;
      case 'paused':
        endpoint = 'state/paused';
        break;
      case 'unpause':
        endpoint = 'state/unpaused';
        break;
    }
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.patch(`${environment.apiPrefix}/v1/decision-trees/${triage.id}/${endpoint}`, {}, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    });
  }
}
