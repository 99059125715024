import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-joii-table-search',
  templateUrl: './joii-table-search.component.html',
  styleUrls: ['./joii-table-search.component.css']
})
export class JoiiTableSearchComponent implements OnInit {

  @Input() searchTerm: string;
  @Input() searchByKeys: Array<string>;
  @Output() searchDetails: EventEmitter<any> = new EventEmitter<any>();
  chosenSearchBy: string;
  searchDebounce: Subject<any> = new Subject();
  subscriptions: Subscription = new Subscription();

  constructor() { }

  ngOnInit() {
    this.setupSearch();
  }

  setupSearch() {
    this.chosenSearchBy = this.searchByKeys[0];
    this.subscriptions.add(this.searchDebounce.asObservable()
      .pipe(
        debounceTime(500)
      )
      .subscribe(x => this.searchItems()));
  }

  searchItems() {
    this.searchDetails.emit({term: this.searchTerm, column: this.chosenSearchBy});
  }

  onSearch() {
    this.searchDebounce.next();
  }

  clearSearch() {
    this.searchTerm = '';
    this.searchDetails.emit({term: this.searchTerm, column: this.chosenSearchBy});
  }

  changeSearchBy() {
    this.searchDetails.emit({term: this.searchTerm, column: this.chosenSearchBy});
  }

}
