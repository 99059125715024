<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <h6>{{selectedTitle}}</h6>
      <div
        class="container" #selectedList
        cdkDropList
        id="selected-list"
        #doneList="cdkDropList"
        [cdkDropListData]="selectedForm.value"
        [cdkDropListConnectedTo]="[todoList]"
        [cdkDropListDisabled]="disabled"
        (cdkDropListDropped)="drop($event)">
        <div class="list {{type}}">
          <mat-expansion-panel
            *ngFor="let item of $any(selectedForm.controls); let i = index"
            (cdkDragStarted)="dragging = !dragging"
            (cdkDragEnded)="dragging = !dragging"
            [disabled]="expansionDisabled"
            cdkDrag
            #expansionPanel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="info-icons" *ngIf="type === 'data-points'">
                  <mat-icon
                    [matTooltip]="'Type: ' + (item.controls['type'].value | titlecase)">info</mat-icon>
                  <mat-icon *ngIf="item.can_set_required" matTooltip="Can Set Required">insights</mat-icon>
                </span>
                <span>{{item.value.name}}</span>
                <ng-container *ngIf="item.controls['warning']">
                  <span
                    *ngIf="item.controls['warning'].value"
                    class="text-danger warning-details">
                    <mat-icon>error</mat-icon>
                    <span class="warning-label">
                      {{item.controls['warning'].value}}
                    </span>
                  </span>
                </ng-container>
              </mat-panel-title>
              <mat-icon *ngIf="shouldDisplay(i) && !expansionPanel.expanded" class="composition">link</mat-icon>
              <div
                *cdkDragPreview
                [style.width]="selectedList.offsetWidth - 16 + 'px'"
                class="moving-box">
                {{item.value.name}}
              </div>
            </mat-expansion-panel-header>
            <div [formGroup]="$any(item)" *ngIf="type === 'data-points'">
              <!-- Default Values Forms Starts -->
              <ng-container [ngSwitch]="item.value.type">
                <div *ngSwitchCase="'number'">
                  <mat-form-field>
                    <mat-label>Default Value</mat-label>
                    <input
                      type="number"
                      matInput
                      placeholder=""
                      formControlName="default_value" />
                  </mat-form-field>
                </div>
                <div *ngSwitchCase="'text'">
                  <mat-form-field>
                    <mat-label>Default Value</mat-label>
                    <input
                      type="text"
                      matInput
                      placeholder=""
                      formControlName="default_value" />
                  </mat-form-field>
                </div>
                <div *ngSwitchCase="'select'">
                  <mat-form-field>
                    <mat-label>Default Value</mat-label>
                    <mat-select
                      formControlName="default_value">
                      <mat-option *ngFor="let opt of item.value.options" [value]="opt.name">
                        {{opt.name}}
                      </mat-option>
                      <mat-option [value]="null">Clear</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngSwitchCase="'multi-select'">
                  <mat-form-field>
                    <mat-label>Default Value</mat-label>
                    <mat-select
                      formControlName="default_value">
                      <mat-option *ngFor="let opt of item.value.options" [value]="opt.name">
                        {{opt.name}}
                      </mat-option>
                      <mat-option [value]="null">Clear</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngSwitchCase="'radio-select'">
                  <mat-form-field>
                    <mat-label>Default Value</mat-label>
                    <mat-select
                      formControlName="default_value">
                      <mat-option *ngFor="let opt of item.value.options" [value]="opt.name">
                        {{opt.name}}
                      </mat-option>
                      <mat-option [value]="null">Clear</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngSwitchCase="'boolean-checkbox'">
                  <mat-form-field>
                    <mat-label>Default Value</mat-label>
                    <mat-select
                      formControlName="default_value">
                      <mat-option [value]="'true'">
                        Checked (true)
                      </mat-option>
                      <mat-option [value]="'false'">
                        Un Checked (false)
                      </mat-option>
                      <mat-option [value]="null">Clear</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngSwitchCase="'boolean-select'">
                  <mat-form-field>
                    <mat-label>Default Value</mat-label>
                    <mat-select
                      formControlName="default_value">
                      <mat-option [value]="'true'">
                        True
                      </mat-option>
                      <mat-option [value]="'false'">
                        False
                      </mat-option>
                      <mat-option [value]="null">Clear</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </ng-container>
              <span class="width-50 meta-fields">
                <mat-checkbox
                  formControlName="required"
                  color="primary">Required</mat-checkbox>
              </span>
              <span class="width-50">
                <mat-checkbox
                  formControlName="sets_required"
                  color="primary" [disabled]="!item.value.can_set_required || !composition">
                  Sets Required
                </mat-checkbox>
                <div>
                  <small><strong>Please Note:</strong> Must be a datapoint that can set required and be a composition to be able to enable "sets required"</small>
                </div>
              </span>
              <span class="width-100">
                <mat-checkbox
                  formControlName="sets_context"
                  color="primary">Sets Context</mat-checkbox>
              </span>
              <!-- Default Values Forms Ends -->
              <!-- Specific Value Forms Starts -->
              <ng-container *ngIf="item.controls">
                <ng-container *ngIf="item.controls['duration']">
                  <mat-form-field>
                    <mat-label>Duration</mat-label>
                    <input
                      type="number"
                      matInput
                      placeholder=""
                      formControlName="duration" />
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="item.controls['interval']">
                  <mat-form-field>
                    <mat-label>Interval</mat-label>
                    <input
                      type="number"
                      matInput
                      placeholder=""
                      formControlName="interval" />
                  </mat-form-field>
                </ng-container>
              </ng-container>
              <!-- Specific Value Forms Ends -->
            </div>
            <mat-icon *ngIf="shouldDisplay(i)" class="composition">link</mat-icon>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
    <div class="col-6">
      <h6>{{availableTitle}}</h6>
      <div class="search-header">
        <mat-form-field appearance="fill" color="primary" class="search-input">
          <input
            type="text"
            matInput
            placeholder="Search Available"
            [(ngModel)]="searchTerm"
            (input)="searchAvailable()" />
        </mat-form-field>
        <button
          *ngIf="searchTerm"
          mat-icon-button
          class="clear-search"
          (click)="clearSearch()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <div class="container search-enabled">
        <div
          #availableList
          cdkDropList
          id="available-list"
          #todoList="cdkDropList"
          [cdkDropListData]="filteredAvailable"
          [cdkDropListConnectedTo]="[doneList]"
          class="list"
          (cdkDropListDropped)="drop($event)"
          [cdkDropListDisabled]="disabled">
          <mat-expansion-panel
            *ngFor="let item of filteredAvailable"
            (cdkDragStarted)="dragging = !dragging"
            (cdkDragEnded)="dragging = !dragging"
            [disabled]="true"
            cdkDrag>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span>{{item.name}}</span>
                <span class="info-icons" *ngIf="type === 'data-points'">
                  <mat-icon *ngIf="item.can_set_required" matTooltip="Can Set Required">insights</mat-icon>
                  <mat-icon
                    [matTooltip]="'Type: ' + (item.type | titlecase) +
                    (item.options.length ? ', Options: x' + item.options.length : '')">info</mat-icon>
                </span>
              </mat-panel-title>
              <div
                *cdkDragPreview
                [style.width]="availableList.offsetWidth - 16 + 'px'"
                class="moving-box">
                <span>{{item.name}}</span>
                <span class="info-icons" *ngIf="type === 'data-points'">
                  <mat-icon *ngIf="item.can_set_required">insights</mat-icon>
                  <mat-icon>info</mat-icon>
                </span>
              </div>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
  </div>
</div>
