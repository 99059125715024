<div *ngIf="data.data" class='container margin-bottom-20'>
    Rules
    <div *ngIf="data.data" cdkDropList cdkDropListData class="example-list margin-bottom-10" (cdkDropListDropped)="drop($event, data)">
        <div *ngFor='let rule of data.data.rules; let i = index' class="example-box width-100 margin-bottom-10" cdkDrag cdkDragLockAxis="y">
            <div style="width:100%;">
                <span class="exitClass">
                    <a (click)='removeRule(rule, data.data)'>&times;</a>
                </span>
                <div style="width:100%; padding-top:5px;padding-left:15px;padding-bottom:10px;padding-right:15px;">
                    Conditions
                </div>
                <div *ngFor="let condition of rule.conditions; let j = index" class="container">
                    <span class="exitClass">
                        <a (click)='removeCondition(condition, j, rule)'>&times;</a>
                    </span>
                    <div class="condition-box container">
                        <label for="question">Type</label>
                        <select
                          class="width-100 margin-bottom-10"
                          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                          [(ngModel)]="condition.condition_type"
                          (change)="updateConditionType(condition)">
                            <option *ngFor="let value of conditionTypes" [ngValue]="value">{{value.toUpperCase()}}</option>
                        </select>
                        <ng-container *ngIf="condition.condition_type === 'variable'">
                            <label for="question">Variable</label>
                            <select
                              class="width-100 margin-bottom-10"
                              [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                              [(ngModel)]="condition.variable"
                              (change)="update(condition)">
                                <option *ngFor="let question of aList">
                                    <span>{{question.variable}}</span>
                                </option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="condition.condition_type === 'formula'">
                            <label for="formula">Formula</label>
                            <button
                              class="btn btn-primary"
                              [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                              (click)="editFormula(i, j, formulaGenerator)"
                              *ngIf="!condition.formula">Generate Formula</button>
                            <div style="text-align: center;" *ngIf="condition.formula">
                                <button
                                  class="btn btn-primary"
                                  (click)="editFormula(i, j, formulaGenerator)"
                                  [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                                  *ngIf="condition.formula === ''">Generate Formula</button>
                                <button
                                  class="btn btn-success"
                                  [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                                  (click)="editFormula(i, j, formulaGenerator)"
                                  *ngIf="condition.formula">Edit</button>
                                <button
                                  class="btn btn-warning"
                                  (click)="openFormulaModal(i, j, formulaPreview)"
                                  [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                                  *ngIf="condition.formula">View</button>
                            </div>
                        </ng-container>
                        <label for="operator">Operator</label>
                        <select
                          class="width-100 margin-bottom-10"
                          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                          [(ngModel)]="condition.comparator">
                            <option *ngFor="let operator of operators">
                                <span>{{operator}}</span>
                            </option>
                        </select>

                        <label for="product_selection">Comparate Type</label>
                        <select
                          class="width-100 margin-bottom-10"
                          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                          [(ngModel)]="condition.comparate_type">
                            <option *ngFor="let value of comparateTypes" [ngValue]="value">{{value}}</option>
                        </select>
                        <div *ngIf="condition.comparate_type === 'variable'">
                            <label for="selection">Comparing Variable</label>
                            <select
                              class="width-100 margin-bottom-10"
                              [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                              [(ngModel)]="condition.comparing_variable"
                              (change)="update(condition)">
                                <option *ngFor="let question of aList">
                                    <span>{{question.variable}}</span>
                                </option>
                            </select>
                        </div>
                        <div *ngIf="condition.comparate_type === 'value'">
                            <span *ngIf="!condition.list || condition.list.length === 0 || condition.list === undefined || condition.condition_type === 'formula'">
                                <label for="selection">Selection</label>
                                <input
                                  class="width-100 form-control margin-bottom-10"
                                  [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                                  [(ngModel)]="condition.value"
                                  type="number">
                            </span>
                            <span *ngIf="condition.list && condition.list.length !== 0 && condition.condition_type !== 'formula'">
                                <label for="selection">Selection</label>
                                <select
                                  class="width-100 margin-bottom-10"
                                  [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                                  [(ngModel)]="condition.value">
                                    <option *ngFor="let option of condition.list" [ngValue]="option.value">
                                        {{option.display_value}}
                                    </option>
                                </select>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="container width-100">
                    <label for="decision">DEFAULT</label>
                    <input
                      style="margin-left:20px;"
                      type="checkbox"
                      [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                      [(ngModel)]="rule.is_default">
                </div>
                <div>
                    <button
                      type="button"
                      class="addOptionBtn width-100"
                      [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                      (click)="addCondition(rule)">
                      Add Condition
                    </button>
                </div>
            </div>
        </div>
        <div>
            <button
              type="button"
              class="addOptionBtn width-100"
              [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
              (click)="addRule()">Add Rule</button>
        </div>
    </div>

</div>
<ng-template #formulaPreview>
  <h2>
    Formula for the decision.
  </h2>
  <br>
  <h6 *ngIf="temporaryFormula">
        <span *ngFor="let element of temporaryFormula">
            {{element}}
        </span>
  </h6>
</ng-template>
<ng-template #formulaGenerator>
  <h2>Create the Formula for the decision</h2>
  <br>
  <h5>Intuitively click the buttons to generate a Formula.</h5>
  <br>
  <div *ngIf="activeFormula">
    <div *ngFor="let item of activeFormula; let i = index;" style="border:1px solid black; margin: 5px;display: inline-block; padding: 5px; border-radius: 5px;" >
            <span class="exit" (click)='removeFormulaElement(i)'>
                &times;
            </span>
      <div style="text-align:center; width: 100%">
        <div style="padding: 20px; font-weight: bolder;">
          {{item}}
        </div>
        <div>
          <button
            class="btn btn-warning"
            style="width: 40px; margin-right: 5px;"
            [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
            (click)="dropFormula('left', i)"><</button>
          <button
            class="btn btn-warning"
            style="width: 40px"
            [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
            (click)="dropFormula('right', i)">></button>
        </div>
      </div>
    </div>
    <div >
      <span *ngFor="let item of activeFormula;"> {{item}} </span>
    </div>
  </div>
  <div class="container-fluid row" style="text-align: center; margin-top: 20px; margin-bottom: 20px">
    <div class="col-lg-2 col-md-2 col-sm-2">
      <div style="background-color:black;padding: 10px;border-radius: 15px;">
        <button
          class="btn btn-success"
          style="margin-right: 5px;"
          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
          (click)="addToFormula('(', 'operator')">(</button>
        <button
          class="btn btn-success"
          style="margin-left: 5px;"
          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
          (click)="addToFormula(')', 'operator')">)</button>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2">
      <div style="background-color:black;padding: 10px;border-radius: 15px;">
        <button
          class="btn btn-primary"
          style="margin-right: 5px;"
          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
          (click)="addToFormula('+', 'operator')">+</button>
        <button
          class="btn btn-primary"
          style="margin-right: 5px;margin-left: 5px;"
          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
          (click)="addToFormula('-', 'operator')">-</button>
        <button
          class="btn btn-primary"
          style="margin-right: 5px;margin-left: 5px;"
          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
          (click)="addToFormula('/', 'operator')">/</button>
        <button
          class="btn btn-primary"
          style="margin-left: 5px;"
          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
          (click)="addToFormula('*', 'operator')">*</button>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3">
      <div style="background-color:black;padding: 10px;border-radius: 15px;">
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
            [(ngModel)]="number"
            placeholder="Number">
          <div class="input-group-append">
            <button
              class="btn btn-danger"
              [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
              (click)="addToFormula(number, 'number')">Add</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2">
      <div style="background-color:black;padding: 10px;border-radius: 15px;">
        <button
          class="btn btn-info"
          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
          (click)="addToFormula('QUESTIONS_ANSWERED', 'variable')">Q's Answered</button>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3">
      <div style="background-color:black;padding: 10px;border-radius: 15px;">
        <div class="input-group">
          <select
            class="form-control"
            [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
            [(ngModel)]="selectedVariable"
            aria-placeholder="Variable">
            <option *ngFor="let question of aList">
              <span>{{question.variable}}</span>
            </option>
          </select>
          <div class="input-group-append">
            <button
              class="btn btn-warning"
              [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
              (click)="addToFormula(selectedVariable, 'variable')">Add Variable</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <button
    class="btn btn-success ready-button"
    style="text-align:center"
    [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
    (click)="completeFormula()">Complete Formula</button>
  <button
    class="btn btn-danger ready-button"
    style="text-align:center"
    [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
    (click)="cancelFormula()">Cancel Formula</button>
</ng-template>
