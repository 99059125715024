<div class="input-group md-form form-sm form-1 pl-0 position-relative">
  <div class="input-group-prepend">
    <span class="input-group-text search-icon" id="basic-text1">
      <i class="fas fa-search" aria-hidden="true"></i>
    </span>
  </div>
  <input
    #filter
    class="form-control my-0 py-1 shadow-none"
    [class.product-search-input]="searchOptions?.length > 0"
    type="text"
    [placeholder]="placeholder || 'Search Table'"
    aria-label="Search"
    [disabled]="disabled"
    [value]="searchTerm"
    [ngModel]="searchTerm"
    (ngModelChange)="onSearch($event)"
    >
  <div class="search-by-type-holder" *ngIf="searchOptions?.length > 0">
    <select
      class="form-control shadow-none"
      [disabled]="disabled"
      [ngModel]="selectedOption"
      [value]="selectedOption"
      (ngModelChange)="onSearchOptionChange($event)"
      >
      <option *ngFor="let option of searchOptions" [value]="option?.value">{{option?.viewValue}}</option>
    </select>
  </div>
  <div class="input-group-append search-clear-holder" *ngIf="searchTerm">
    <span class="input-group-text search-clear">
      <button
        mat-icon-button
        class="remove-btn"
        (click)="clearSearch()">
        <span><mat-icon>clear</mat-icon></span>
      </button>
    </span>
  </div>
