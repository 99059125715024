import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Next } from '../../../../shared/models/interface';

import { ImageValidatorService } from '../../../../shared/services/image-validator.service';
import { TriageService } from '../../../../shared/services/triage-service.service';
import { AlertService } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-sidebar-issue-comparison',
  templateUrl: './sidebar-issue-comparison.component.html',
  styleUrls: ['./sidebar-issue-comparison.component.css']
})
export class SidebarIssueComparisonComponent implements OnInit {
  @Input() data: any;
  uploadedFile: any;
  editing: boolean;

  constructor(private alertService: AlertService,
              private triageService: TriageService,
              private imageValidatorService: ImageValidatorService) { }

  ngOnInit() {
  }

  drop(event: CdkDragDrop<any[]>, node) {
    node.data.options[event.currentIndex].order = event.previousIndex;
    node.data.options[event.previousIndex].order = event.currentIndex;
    moveItemInArray(node.data.options, event.previousIndex, event.currentIndex);
  }

  alertDrag(item, event) {
  //     if (this.itemDragging === item) {

  //     } else {
  //       this.itemDragging = item;
  //       this.dataService.alertDragRequest(item);
  //     }
  }

  fileChangeEvent(event: any, child): void {
      this.imageValidatorService.validateImageSize(event.target.files[0], 'issue-comparison').then((checked) => {
        this.uploadedFile = event.target.files[0];
        const formData = new FormData();
        formData.append('image', this.uploadedFile, this.uploadedFile.name);
        this.triageService.uploadImage(formData).subscribe((data: any) => {
          child.media_url = 'https://storage.googleapis.com/assets.vet-ai.com/' + data.filenames[0];
        });
      }, error => {
        this.alertService.error('Image must be 1:1 ratio');
      });
  }


  copyOption(o: any): any {
    return JSON.parse(JSON.stringify(o));
  }

  addOption(node) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      const option = {
        display_value: '',
        description: '',
        popover: '',
        value: 0,
        order: 0,
        url: '',
        media_url: '',
        price: 0,
      };
      if (node.data.options === null || node.data.options === undefined) {
        node.data.options = [];
      } else {
        node.data.options.push(option);
      }
    }
  }

  removeOption(selected: Next, node) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      for (let i = 0; i < node.data.options.length; i++) {
        if (selected === node.data.options[i]) {
          node.data.options.splice(i, 1);
        }
      }
    }
  }

  removeImage(child) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      child.media_url = null;
    }
  }
}
