<header class="main-header">
  <div class="main-logo" matTooltip="Vet AI - CMS">
    <img src="./assets/logos/vet-ai-logo.svg" />
  </div>
  <div
    class="version-number"
    [matTooltip]="'Version ' + versionNumber">
    v{{versionNumber}}
  </div>
  <div *ngIf="user" class="profile-image">
    <span
      class="profile-trigger"
      [matTooltip]="user.user.full_name"
      [matMenuTriggerFor]="userMenu">
      <span *ngIf="user.user.profile.image">
        <img [src]="user.user.profile.image" />
      </span>
      <span *ngIf="!user.user.profile.image" class="user-placeholder">
        <mat-icon>person</mat-icon>
      </span>
    </span>
  </div>
  <mat-menu #userMenu="matMenu" yPosition="below">
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</header>
<nav class="main-nav">
  <div *ngFor="let item of menuItems" class="menu-btn-holder">
    <button
      *ngIf="!item.isAdmin"
      mat-button
      (click)="setRoute(item.path)"
      [class.selected-btn]="currentRoute === item.path">{{item.path | removeHyphen}}</button>
  </div>
  <div class="menu-btn-holder" *ngIf="user">
    <button
      *ngIf="user.user.role === 'super-admin' || user.user.role === 'admin'"
      mat-button
      [class.selected-btn]="isAdminRoute"
      (click)="goToAdminRoute()">
      Admin
    </button>
  </div>
</nav>
<nav class="sub-nav" *ngIf="isAdminRoute && user">
  <div class="sub-menu">
    <span *ngFor="let item of menuItems">
      <button
        *ngIf="item.isAdmin"
        mat-button
        (click)="setRoute(item.path)"
        [class.selected-btn]="currentRoute === item.path">{{item.path | removeHyphen}}</button>
    </span>
  </div>
</nav>
