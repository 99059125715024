import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';
import { StaffProfile } from '../models/profile';

@Injectable()
export class AuthGuard implements CanActivate {

    staffLoggedIn: StaffProfile;

    constructor(private router: Router,
                private permissionService: PermissionsService,
                private alertService: AlertService,
                private authService: AuthenticationService) { }

    navigateToRoute(location, state) {
        this.router.navigate([location], { queryParams: { returnUrl: state.url } });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkIfCanActivateRoute(route.routeConfig.path, state);
    }

    checkIfCanActivateRoute(path, state) {
        let returnValue = false;
        const token = JSON.parse(localStorage.getItem('currentUser'));
        if (token) {
            if (path  === 'login') {
                this.navigateToRoute('/home', state);
            }
            returnValue = this.handleSpecificRoutePermissions(path);
        } else {
            this.navigateToRoute('/login', state);
        }
        return returnValue;
    }

    handleSpecificRoutePermissions(path) {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        if (path.includes('triage') && path !== 'triage-products') {
            path = 'triage';
        }
        let returnValue;
        switch (path) {
            case 'permissions':
              returnValue = user.user.role === 'super-admin';
              break;
            default:
              returnValue = this.permissionService.checkForAccessPermissionsBasedOnRequirement(path, 'read', user);
              break;
        }
        this.alertBasedOnReturnValue(returnValue, path);
        return returnValue;
    }

    alertBasedOnReturnValue(returnValue, path) {
        if (!returnValue) {
            this.alertService.error('You do not have the appropriate permissions to access ' + path);
        }
    }
}
