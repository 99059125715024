import { Injectable } from '@angular/core';
import { SidebarIssueLocationComponent } from '../../triage/components/sidebar-form/sidebar-issue-location/sidebar-issue-location.component';
import { SidebarMultipleChoiceComponent } from '../../triage/components/sidebar-form/sidebar-multiple-choice/sidebar-multiple-choice.component';
import { SidebarSeeAVetComponent } from '../../triage/components/sidebar-form/sidebar-see-a-vet/sidebar-see-a-vet.component';
import { SidebarItem } from '../../triage/components/sidebar-form/sidebar-item';
import { ProductRecommendationComponent } from '../../triage/components/sidebar-form/product-recommendation/product-recommendation.component';
import { SidebarDecisionComponent } from '../../triage/components/sidebar-form/sidebar-decision/sidebar-decision.component';
import { SidebarSeverityComponent } from '../../triage/components/sidebar-form/sidebar-severity/sidebar-severity.component';
import { SidebarIssueComparisonComponent } from '../../triage/components/sidebar-form/sidebar-issue-comparison/sidebar-issue-comparison.component';
import { SidebarImageSelectionComponent } from '../../triage/components/sidebar-form/sidebar-image-selection/sidebar-image-selection.component';
import { SidebarInformationComponent } from '../../triage/components/sidebar-form/sidebar-information/sidebar-information.component';
import { SidebarProductSelectionComponent } from '../../triage/components/sidebar-form/sidebar-product-selection/sidebar-product-selection.component';
import { SidebarUserInputComponent } from '../../triage/components/sidebar-form/sidebar-user-input/sidebar-user-input.component';
import { SidebarVideoConsultComponent } from '../../triage/components/sidebar-form/sidebar-video-consult/sidebar-video-consult.component';
import { SidebarEmptyComponent } from '../../triage/components/sidebar-form/sidebar-empty/sidebar-empty.component';
import { SidebarImagesComponent } from '../../triage/components/sidebar-form/sidebar-images/sidebar-images.component';
import { SidebarVideosComponent } from '../../triage/components/sidebar-form/sidebar-videos/sidebar-videos.component';
import { SidebarCustomOutcomeComponent } from '../../triage/components/sidebar-form/sidebar-custom-outcome/sidebar-custom-outcome.component';
import { SidebarAppointmentTypeComponent } from '../../triage/components/sidebar-form/sidebar-appointment-type/sidebar-appointment-type.component';
import { SidebarSliderComponent } from '../../triage/components/sidebar-form/sidebar-slider/sidebar-slider.component';

@Injectable()
export class SidebarFormService {
  constructor() {}

  getSidebarForm(data) {
    console.log('function called with data of', data.data.type);
    console.log('function called with type of', data.data.type.type);
    console.log('function called with subtype of', data.data.type.subtype);
    console.log(data.data.type.type === 'input' && data.data.type.subtype === 'text');
    if (data.data.type.type === 'decision') {
      console.log('returning a sidebar decision component');
      return new SidebarItem(SidebarDecisionComponent, data);
    }
    if (data.data.type.type === 'input' && data.data.type.subtype === 'text') {
      console.log('returning user input component');
      return new SidebarItem(SidebarUserInputComponent, data);
    }
    if(data.data.type.type !== 'decision' && !data.data.type.subtype) {
      console.log('returning sidebar empty component');
      return new SidebarItem(SidebarEmptyComponent, data);
    }
    if (data.data.type.subtype === 'see-a-vet') {
      return new SidebarItem(SidebarSeeAVetComponent, data);
    }
    if (data.data.type.subtype === 'multiple-choice' || data.data.type.subtype === 'multiple-choice-selection') {
      return new SidebarItem(SidebarMultipleChoiceComponent, data);
    }
    if (data.data.type.subtype === 'severity') {
      return new SidebarItem(SidebarSeverityComponent, data);
    }
    if (data.data.type.subtype === 'slider') {
      return new SidebarItem(SidebarSliderComponent, data);
    }
    if (data.data.type.subtype === 'issue-location') {
      return new SidebarItem(SidebarIssueLocationComponent, data);
    }
    if (data.data.type.subtype === 'product-recommendation') {
      return new SidebarItem(ProductRecommendationComponent, data);
    }
    if (data.data.type.subtype === 'issue-comparison') {
      return new SidebarItem(SidebarIssueComparisonComponent, data);
    }
    if (data.data.type.subtype === 'image-selection') {
      return new SidebarItem(SidebarImageSelectionComponent, data);
    }
    if ((data.data.type.type === 'information' && data.data.type.subtype === 'text') ||
      (data.data.type.type === 'outcome' && data.data.type.subtype === 'information-text')) {
      return new SidebarItem(SidebarInformationComponent, data);
    }

    if ((data.data.type.type === 'information' && data.data.type.subtype === 'image') ||
      (data.data.type.type === 'outcome' && data.data.type.subtype === 'information-image')) {
      return new SidebarItem(SidebarImagesComponent, data);
    }

    if ((data.data.type.type === 'information' && data.data.type.subtype === 'video') ||
      (data.data.type.type === 'outcome' && data.data.type.subtype === 'information-video')) {
      return new SidebarItem(SidebarVideosComponent, data);
    }

    if (data.data.type.subtype === 'product-selection') {
      return new SidebarItem(SidebarProductSelectionComponent, data);
    }

    if (data.data.type.subtype === 'video-consultation' ||
      data.data.type.subtype === 'nurse-consultation' ||
      data.data.type.subtype === 'vet-consultation' ||
      data.data.type.subtype === 'specialist-consultation') {
      return new SidebarItem(SidebarVideoConsultComponent, data);
    }
    if (data.data.type.subtype === 'information-outcome') {
      return new SidebarItem(SidebarInformationComponent, data);
    }

    if (data.data.type.type === 'outcome' && data.data.type.subtype === 'custom') {
      return new SidebarItem(SidebarCustomOutcomeComponent, data);
    }

    if (data.data.type.type === 'outcome' && data.data.type.subtype === 'non-emergency') {
      return new SidebarItem(SidebarAppointmentTypeComponent, data);
    }
  }
}
