import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent {

  @Input() question: any;
  @Input() inlineQuestion: boolean;
  @Output() checkAnswers: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  checkAnswersNow() {
    this.checkAnswers.emit(this.question);
  }

  pickMultiOption(question, event) {
    if (!question.value) {
      question.value = [];
    }
    question.input = '';
    if (question.value.indexOf(event.option.value) === -1) {
      question.value.push(event.option.value);
      question.chipsSelected.push(event.option.value);
    }
    this.checkAnswersNow();
  }

  removeMultiOption(question, item) {
    const index = question.chipsSelected.indexOf(item);
    if (index >= 0) {
      question.value.splice(index, 1);
      question.chipsSelected.splice(index, 1);
    }
    if (question.value.length === 0) {
      question.value = null;
    }
    this.checkAnswersNow();
  }

  resizeInput(textLength) {
    const textString = `${textLength || ''}`;
    return textString.length + 1 + 'ch';
  }
}
