<div class="floating-menu">
    <p>Change status to:</p>
    <button
      *ngIf="product.status === 'ready'" 
      class="btn btn-primary live"
      (click)="setStatus('published')">
      Live
    </button>
    <button
      *ngIf="product.status === 'ready'"
      class="btn btn-primary draft"
      (click)="setStatus('draft')">
      Draft
    </button>
    <button
      *ngIf="product.status === 'draft'"
      class="btn btn-primary ready"
      (click)="setStatus('ready')">
      Ready
    </button>
</div>
  