import { Component, Input, OnInit } from '@angular/core';
import { SidebarComponentInterface } from '../sidebar-item.component';
@Component({
  selector: 'app-sidebar-issue-location',
  templateUrl: './sidebar-issue-location.component.html',
  styleUrls: ['./sidebar-issue-location.component.css']
})
export class SidebarIssueLocationComponent implements SidebarComponentInterface, OnInit {
  @Input() data: any;

  ngOnInit() {
    // if (this.data.data.options === null || this.data.data.options === []) {
    //   this.data.data.options = [];
    //   const option = {
    //       comparator: '',
    //       id: -1,
    //       info: '',
    //       next: {
    //         id: -1,
    //         text: '',
    //         title: 'Issue Location',
    //         type: 'issue-location'
    //       },
    //       text: '',
    //       value: 0,
    //     };
    //       this.data.data.options = [option];
    //     }
    }
  copyOption(o: any): any {
    return JSON.parse(JSON.stringify(o));
  }

}
