<section class="container">
  <p class="instructions-text"><small>For more information on how to get a YouTube ID, please
    <a href="https://doc.clickup.com/d/h/29k1f-3424/d9fd362ba54bba0" target="_blank">click here.</a></small></p>
  <article
    *ngIf="videos.length > 0"
    id="drop-list"
    class="videos-list"
    cdkDropList
    [cdkDropListDisabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
    (cdkDropListDropped)="dropVideo($event)">
    <div *ngFor="let video of videos; let i = index;" cdkDrag>
      <hr *ngIf="i > 0" />
      <button
        class="btn btn-block btn-danger remove-btn"
        [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
        (click)="removeVideo(i)">Remove video</button>
      <iframe
        [src]="videosDisplay[i]"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      <label>Description</label>
      <textarea
        [(ngModel)]="video.description"
        [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
        class="width-100 form-control"></textarea>
    </div>
  </article>
  <input
    [(ngModel)]="videoInput"
    type="text"
    class="width-100 form-control margin-bottom-10"
    placeholder="Enter YouTube Video ID" />
  <button
    class="btn btn-primary width-100 margin-bottom-10"
    [disabled]="!videoInput || data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
    (click)="addVideo(videoInput)">Add video</button>
</section>
