import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-sidebar-videos',
  templateUrl: './sidebar-videos.component.html',
  styleUrls: ['./sidebar-videos.component.css']
})
export class SidebarVideosComponent implements OnInit {

  @Input() public data: any;
  videos: Array<any> = [];
  videosDisplay: Array<SafeResourceUrl> = [];
  videoInput: string;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.data.data.options) {
      this.data.data.options.forEach(video => {
        this.videosDisplay.push(this.sanitizer.bypassSecurityTrustResourceUrl(video.media_url));
      });
      this.videos = [...this.data.data.options];
    }
  }

  dropVideo(event) {
    moveItemInArray(this.data.data.options, event.previousIndex, event.currentIndex);
    this.videos = [...this.data.data.options];
    this.reorderVideos();
  }

  reorderVideos() {
    this.videosDisplay = [];
    this.videos.forEach(item => {
      this.videosDisplay.push(this.sanitizer.bypassSecurityTrustResourceUrl(item.media_url));
    });
  }

  removeVideo(index) {
    this.videos.splice(index, 1);
    this.data.data.options.splice(index, 1);
    this.videosDisplay.splice(index, 1);
  }

  addVideo(videoID) {
    if (!this.data.data.options) {
      this.data.data.options = [];
    }
    const videoLink = 'https://www.youtube.com/embed/' + videoID;
    const videoObject = {
      order: this.videos.length,
      description: '',
      media_url: videoLink
    };
    this.videos.push(videoObject);
    this.data.data.options.push(videoObject);
    this.videosDisplay.push(this.sanitizer.bypassSecurityTrustResourceUrl(videoLink));
    this.videoInput = null;
  }

}
