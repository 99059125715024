import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-empty',
  templateUrl: './sidebar-empty.component.html',
  styleUrls: ['./sidebar-empty.component.css']
})
export class SidebarEmptyComponent implements OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

}
