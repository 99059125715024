<div
  [ngSwitch]="question.type"
  [class.inline-question]="inlineQuestion">
  <!-- Text Input Starts -->
  <div *ngSwitchCase="'text'">
    <input
      matInput
      type="text"
      class="form-control"
      placeholder="Enter text"
      [(ngModel)]="question.value"
      (ngModelChange)="checkAnswersNow()" />
  </div>
  <!-- Text Input Ends -->
  <!-- Number Input Starts -->
  <div *ngSwitchCase="'number'">
    <div class="input-container">
      <label>{{question?.name ? question.name : 'Enter a number'}}</label>
      <span class="actions">
              <span class="prefix" *ngIf="question?.sub_type === 'pound'">£</span>
              <input
                matInput
                type="number"
                step="0.01"
                size="{{(question?.value?.length || 0) +1}}"
                class="form-control question-input"
                autocomplete="off"
                placeholder=""
                [(ngModel)]="question.value"
                (ngModelChange)="checkAnswersNow()" />
            <span class="suffix" *ngIf="question?.sub_type === 'percentage'">%</span>
      </span>
    </div>
  </div>
  <!-- Number Input Ends -->
  <!-- Date Input Starts -->
  <div *ngSwitchCase="'date'">
    <div class="datepicker-holder">
      <input
        matInput
        class="form-control"
        placeholder="Select a date"
        [matDatepicker]="picker"
        [(ngModel)]="question.value"
        (ngModelChange)="checkAnswersNow()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </div>
  <!-- Date Input Ends -->
  <!-- Select Input Starts -->
  <div *ngSwitchCase="'select'">
    <mat-select
      class="form-control"
      placeholder="Select an option"
      [(ngModel)]="question.value"
      (ngModelChange)="checkAnswersNow()">
      <mat-option *ngFor="let option of question.options" [value]="option.value">{{option.name}}</mat-option>
    </mat-select>
  </div>
  <!-- Select Input Ends -->
  <!-- Multi Select Input Starts -->
  <div *ngSwitchCase="'multi-select'">
    <mat-form-field class="multi-select-holder">
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let item of question.chipsSelected"
          [selectable]="true"
          [removable]="true"
          (removed)="removeMultiOption(question, item)">
          {{item}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          class="multi-select-input"
          [(ngModel)]="question.input"
          [matAutocomplete]="auto"
          [matAutocompletePosition]="'above'"
          [matChipInputFor]="chipList">
        <span class="sudo-placeholder" *ngIf="!question.value">Select as many as apply</span>
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="pickMultiOption(question, $event)">
        <mat-option *ngFor="let option of question.options" [value]="option.value">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

</div>
