import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, mergeMap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {VersionUpdateDialogComponent} from '../components/version-update-dialog/version-update-dialog/version-update-dialog.component';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService implements OnDestroy {

  private versionNumber = '1.4.22';
  private versionCheck: Subject<void> = new Subject();

  private subscriptions: Subscription = new Subscription();

  constructor(private readonly http: HttpClient, private readonly dialog: MatDialog) {
    this.init();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  checkVersionNumber() {
    this.versionCheck.next();
  }

  getVersionNumber(): string {
    return this.versionNumber;
  }

  private init() {
    this.subscriptions.add(this.versionCheck.pipe(
      mergeMap(() => this.getCmsVersionFromApi())
    ).subscribe((cmsVersion: any) => {
        if (cmsVersion && cmsVersion.length && this.versionNumber !== cmsVersion) {
          this.dialog.open(
            VersionUpdateDialogComponent,
            {
              panelClass: 'version-dialog',
              backdropClass: 'version-dialog-backdrop',
              disableClose: true,
              position: {top: '1.75rem'},
              minWidth: 'calc(464px + 1rem)',
              minHeight: 'calc(286px + 1rem)'
            }
          );
          return;
        }
      },
      (error) => {
        console.error(`Error getting version number for API.`);
        console.error(error);
      }));
  }

  private getCmsVersionFromApi(): Observable<string> {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.apiPrefix}/v1/version`, {
      headers: {
        Authorization: 'Bearer ' + token.access_token.access_token
      }
    }).pipe(
      map((result: any) => {
        const cmsVersion = result.versions.find((x) => x.platform === 'cms');
        return cmsVersion.version_code;
      })
    );
  }
}
