<div *ngIf="data.data" class='container margin-bottom-20'>
    <label for="issue-comparison">Options</label>
    <div>
      <small><em>Images must be 1:1 aspect ratio</em></small>
    </div>
    <div
      cdkDropList
      cdkDropListData
      class="example-list margin-bottom-10"
      (cdkDropListDropped)="drop($event, data)">
        <div
          *ngFor='let child of data.data.options; let i = index'
          class="example-box"
          cdkDrag
          cdkDragLockAxis="y"
          (cdkDragMoved)="alertDrag(child, $event)">
            <span *ngIf="child">
                <span class="exitClass">
                  <a (click)='removeOption(child, data)'>&times;</a>
                </span>
                <div class="container">
                    <div *ngIf="!child.media_url">
                        <label for="media_url">Image URL</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Image"
                          [(ngModel)]="child.media_url" disabled/>
                        <input type="file" hidden #fileInput (change)="fileChangeEvent($event, child)" class="width-100"/>
                        <button class="btn btn-primary btn-block margin-bottom-20" (click)="fileInput.click()">Choose file</button>
                    </div>
                    <div *ngIf="child.media_url" class="image-holder">
                      <span class="close-btn" (click)="removeImage(child)" title="Remove image">
                        <i class="fas fa-times-circle"></i>
                      </span>
                      <img [src]="child.media_url" />
                    </div>
                    <div>
                        <label for="display_value">Display Value</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Display Value"
                          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                          [(ngModel)]="child.display_value"/>
                    </div>
                    <div>
                        <label for="value">Value</label>
                        <input
                          class="form-control"
                          type="number"
                          placeholder="Value"
                          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                          [(ngModel)]="child.value"/>
                    </div>
                </div>
            </span>
      </div>
      <div *ngIf="!data.data.options || data.data.options.length === 0" class="padding-10">
          Please add an image for the issue comparison.
      </div>
    </div>
    <div class="text-center margin-bottom-10">
        <button
          type="button"
          class="addOptionBtn"
          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
          (click)='addOption(data)'>Add Issue Image +</button>
    </div>
</div>
