<section class="products-table">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id" sticky="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> ID. </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        <span
          *ngIf="element?.draft"
          class="draft-toggle"
          [class.showing-draft]="element?.showingDraft"
          (click)="showDraftProducts(element); $event.stopPropagation()">
          <mat-icon>navigate_next</mat-icon>
        </span>
        <span class="draft-arrow" *ngIf="element?.isDraft">
          <mat-icon>subdirectory_arrow_right</mat-icon>
        </span>
        {{element.id}}
      </td>
    </ng-container>
    <ng-container matColumnDef="name" sticky="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Name </th>
      <td mat-cell *matCellDef="let element" class="padding-5"> {{element.name}} </td>
    </ng-container>
    <!--<ng-container matColumnDef="images" sticky="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Images </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        <ng-container *ngIf="element.images">
          <span *ngIf="element.images.length > 0 && element.images[0].image_url">
            <a [href]="element.images[0].image_url" class="image-holder" target="_blank">
              <img [src]="element.images[0].image_url">
            </a>
          </span>
        </ng-container>
      </td>
    </ng-container>-->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Description </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        <div (click)="element.showMore = true" *ngIf="!element.showMore"> {{element.description | limitTo:50}}</div>
        <div (click)="element.showMore = false" *ngIf="element.showMore"> {{element.description}}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="prices">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Price </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        <span>{{element.price.price | currencyPrice}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="margin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Margin </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        <span matTooltip="{{$any(element?.margin?.status) | capitalizeStringPipe:true:false}}" class="{{element?.margin?.colour}}">{{element?.margin?.value || 0}}%</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="active_ingredients">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Active Ingredients </th>
      <td mat-cell *matCellDef="let element" class="padding-5"> {{element.active_ingredients}} </td>
    </ng-container>
    <ng-container matColumnDef="administration_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Administration Type </th>
      <td mat-cell *matCellDef="let element" class="padding-5"> {{element.administration_type}} </td>
    </ng-container>
    <ng-container matColumnDef="categories">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Categories </th>
      <td mat-cell *matCellDef="let element" class="padding-5"> {{element.categoriesNames}} </td>
    </ng-container>
    <ng-container matColumnDef="efficacy_score">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Efficacy Scores </th>
      <td mat-cell *matCellDef="let element" class="padding-5"> {{element.efficacyScores}} </td>
    </ng-container>
    <ng-container matColumnDef="frequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Frequency </th>
      <td mat-cell *matCellDef="let element" class="padding-5"> {{element.frequency}} </td>
    </ng-container>
    <ng-container matColumnDef="frequency_unit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Frequency Unit </th>
      <td mat-cell *matCellDef="let element" class="padding-5"> {{element.frequency_unit}} </td>
    </ng-container>
    <ng-container matColumnDef="manufacturer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Manufacturer </th>
      <td mat-cell *matCellDef="let element" class="padding-5"> {{element.manufacturer}} </td>
    </ng-container>
    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> SKU </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        {{element.sku}}
      </td>
    </ng-container>
    <ng-container matColumnDef="legal_category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Legal Category </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        {{element.legal_category}}
      </td>
    </ng-container>
    <ng-container matColumnDef="unit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Unit </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        {{element.unit}}
      </td>
    </ng-container>
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Quantity </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        {{element.quantity}}
      </td>
    </ng-container>
    <ng-container matColumnDef="special_instructions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Special Instructions </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        {{element.special_instructions}}
      </td>
    </ng-container>
    <ng-container matColumnDef="supplier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Supplier </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        {{element.supplier}}
      </td>
    </ng-container>
    <ng-container matColumnDef="species">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Species </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        <ng-container *ngFor="let species of element.species">
          {{species}}
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="can_subscribe">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> Subscribable </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        <button
          mat-icon-button
          [disabled]="userWriteAccess"
          (click)="setProductSubscribable(element)">
          <mat-icon class="text-success" *ngIf="element.can_subscribe">done</mat-icon>
          <mat-icon class="text-danger" *ngIf="!element.can_subscribe">close</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="issues">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5">Issues</th>
      <td mat-cell *matCellDef="let element" class="padding-5 error-col">
        <span *ngIf="!element?.errors || element?.errors?.length === 0">
          <mat-icon matTooltip="No issues" class="text-success">done</mat-icon>
        </span>
        <mat-icon *ngIf="element?.errors?.length > 0"
                  class="material-icons-round"
                  [matTooltip]="element?.error_message"
                  matTooltipClass="multi-line-tooltip"
        >warning</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="in_store">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> In Store </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        <button
          mat-icon-button
          *ngIf="!element.isDraft"
          [disabled]="userWriteAccess"
          (click)="setProductInStore(element)">
          <mat-icon class="text-success" *ngIf="element.in_store">done</mat-icon>
          <mat-icon class="text-danger" *ngIf="!element.in_store">close</mat-icon>
        </button>
        <span class="na-message" *ngIf="element.isDraft">n/a</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="in_stock">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="padding-5"> In Stock </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        <button
          mat-icon-button
          *ngIf="!element.isDraft"
          [disabled]="userWriteAccess"
          (click)="setProductInStock(element)">
          <mat-icon class="text-success" *ngIf="element.availability.available">done</mat-icon>
          <mat-icon class="text-danger" *ngIf="!element.availability.available">close</mat-icon>
        </button>
        <span class="na-message" *ngIf="element.isDraft">n/a</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status. </th>
      <td
        mat-cell
        *matCellDef="let element"
        [class.status-draft]="element.status === 'draft'"
        [class.status-ready]="element.status === 'ready'"
        [class.status-published]="element.status === 'published'"
        [class.status-none]="!element.status"
        matTooltip="Please go to the Product Group to manage status"
        [matTooltipDisabled]="element?.group_id === null"
        (click)="setProductStatusToggle(element); $event.stopPropagation()">
        {{element.status}}
        <div *ngIf="element.showToggle">
          <app-set-product-status
            [product]="element"
            (setProductStatus)="setProductStatus(element, $event)"></app-set-product-status>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions" class="actions-col">
      <th mat-header-cell *matHeaderCellDef>
        <span class="in-stock-label" *ngIf="canEditStock">
          In Stock
        </span>
        <button class="focus-none" mat-button [matMenuTriggerFor]="menu"><mat-icon>add</mat-icon> Add New Product</button>
        <mat-menu #menu="matMenu" class="edit-menu">
          <button
            class="focus-none edit-menu-button"
            mat-menu-item
            matTooltip="CREATE: New Product"
            [disabled]="userWriteAccess"
            (click)="openAddEditProductDialog()"
          >
            <mat-icon>add</mat-icon>
            <span>Create Product</span>
          </button>
          <button
            class="focus-none edit-menu-button"
            mat-menu-item
            matTooltip="CREATE: New Product Group"
            [disabled]="userWriteAccess"
            (click)="openAddGroupEditProductDialog()"
          >
            <mat-icon>group_add</mat-icon>
            <span>Create Product Group</span>
          </button>
        </mat-menu>
      </th>
      <td mat-cell *matCellDef="let element" class="padding-5">
        <button
          *ngIf="!element?.group_id"
          mat-icon-button
          color="accent"
          matTooltip="EDIT: {{element.name}}"
          [disabled]="userWriteAccess"
          (click)="openAddEditProductDialog(element)">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>
        <button
          *ngIf="element.group_id"
          mat-icon-button
          color="primary"
          class="group-icon"
          matTooltip="EDIT GROUP: {{element.name}}"
          [disabled]="userWriteAccess"
          (click)="openAddGroupEditProductDialog(element)">
          <mat-icon aria-label="Edit Group">group</mat-icon>
        </button>
        <button
          mat-icon-button
          color="accent"
          matTooltip="DELETE: {{element.name}}"
          [disabled]="userWriteAccess || element?.group_id !== null"
          (click)="openDeleteProductDialog(element)">
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
    <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns;"
    [class.has-draft]="row?.draft"
    [class.is-draft]="row?.isDraft"
    [class.table-success]="row?.is_new"
    [class.table-danger]="row?.is_removed"
    [class.has-error]="row?.errors?.length > 0"
    (click)="$event.stopPropagation()"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[25, 50, 100, 250, 500]" showFirstLastButtons></mat-paginator>
</section>
