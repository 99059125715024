import { Component, OnInit, Input} from '@angular/core';
import { SidebarComponentInterface } from '../sidebar-item.component';

@Component({
  selector: 'app-sidebar-see-a-vet',
  templateUrl: './sidebar-see-a-vet.component.html',
  styleUrls: ['./sidebar-see-a-vet.component.css']
})
export class SidebarSeeAVetComponent implements OnInit {

  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

}
