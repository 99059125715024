import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-version-update-dialog',
  templateUrl: './version-update-dialog.component.html',
  styleUrls: ['./version-update-dialog.component.css']
})
export class VersionUpdateDialogComponent implements OnInit {

  constructor(@Inject('Window') window: Window) { }

  ngOnInit() {
  }

  public onReload() {
    window.location.reload();
  }

}
