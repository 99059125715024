<div class="images-manager">
  <label *ngIf="label">{{label}}</label>
  <ng-container [ngSwitch]="selectorType">
    <ng-container *ngSwitchCase="'gallery'">
      <ng-container *ngTemplateOutlet="Gallery"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'single'">
      <ng-container *ngTemplateOutlet="Single"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'single-square'">
      <ng-container *ngTemplateOutlet="SingleSquare"></ng-container>
    </ng-container>
  </ng-container>

  <input
    type="file"
    hidden
    [disabled]="isDisabled"
    #fileInput
    (change)="uploadImage($event)" />
</div>

<ng-template #Single>
  <div *ngIf="images" class="image-holder">
    <img [src]="images" alt="image" />
    <button
      mat-icon-button
      mat-flat-button
      color="warn"
      class="remove-image-btn"
      matTooltip="Remove image"
      [disabled]="isDisabled"
      (click)="removeImage(images)">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <button
    mat-icon-button
    mat-flat-button
    color="primary"
    matTooltip="{{(images | typeof) === 'string' && images?.length > 2 ? 'Edit image' : 'Add image'}}"
    [disabled]="isDisabled"
    (click)="fileInput.click()">
    <mat-icon>{{(images | typeof) === 'string' && images?.length > 2 ? 'edit' : 'add'}}</mat-icon>
  </button>
</ng-template>

<ng-template #SingleSquare>
<span
  *ngIf="images && (images | typeof) === 'string'"
  class="featured-holder image"
  [class.featured-image-disabled]="isDisabled"
  [class.remove-hovered]="removeHovered">
                  <button
                    class="btn"
                    matTooltip="Edit image"
                    [disabled]="isDisabled"
                    [matTooltipDisabled]="isDisabled"
                    (click)="fileInput.click()">
                    <mat-icon>{{(images | typeof) === 'string' && images?.length > 2 ? 'edit' : 'add'}}</mat-icon>
                  </button>
                  <img [src]="images" />
                  <button
                    mat-icon-button
                    mat-flat-button
                    color="warn"
                    class="remove-image-btn small"
                    matTooltip="Remove image"
                    [disabled]="isDisabled"
                    (mouseover)="onRemoveMouseOver()"
                    (mouseout)="onRemoveMouseOut()"
                    (click)="removeImage(images)">
                  <mat-icon>clear</mat-icon>
    </button>
                </span>
  <span
    *ngIf="!images || images?.length === 0"
    class="featured-holder"
    [class.featured-image-disabled]="isDisabled">
                  <button
                    class="btn"
                    [disabled]="isDisabled"
                    matTooltip="Add image"
                    [matTooltipDisabled]="isDisabled"
                    (click)="fileInput.click()">
                    <mat-icon>add</mat-icon>
                  </button>
                </span>
</ng-template>

<ng-template #Gallery>
  <div
    *ngIf="images?.length > 0"
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="sortImages($event)"
    class="images-drop-holder">
    <div *ngFor="let image of images" class="image-holder" cdkDrag>
      <img [src]="image.image_url" alt="image" />
      <button
        mat-icon-button
        mat-flat-button
        color="warn"
        matTooltip="Remove image"
        class="remove-image-btn"
        [disabled]="isDisabled"
        (click)="removeImage(image)">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <button
    mat-icon-button
    mat-flat-button
    color="primary"
    matTooltip="Add image"
    [disabled]="isDisabled"
    (click)="fileInput.click()">
    <mat-icon>add</mat-icon>
  </button>
</ng-template>
