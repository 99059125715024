import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CollectionInterface} from '../../models/collection.interface';

@Component({
  selector: 'app-set-collection-status',
  templateUrl: './set-collection-status.component.html',
  styleUrls: ['./set-collection-status.component.css']
})
export class SetCollectionStatusComponent implements OnInit {
  @Input() collection: CollectionInterface;
  @Output() setCollectionStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  setStatus(status: boolean) {
    this.setCollectionStatus.emit(status);
  }
}
