import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlgorithmType, AlgorithmSubtype } from '../../shared/models/interface';

@Injectable({
  providedIn: 'root'
})
export class TriageViewModel {

  treeSubject: Subject<any> = new Subject();

  private _tree: any;
  get tree(): any {
    return this._tree;
  }
  set tree(value) {
    this._tree = value;
    this.treeSubject.next(this._tree);
  }

  /** The list of all algorithm types. */
  get algorithmTypes(): AlgorithmType[] {
    let arr: AlgorithmType[] = [];
    for (let type in AlgorithmType) {
      arr.push(AlgorithmType[type] as AlgorithmType);
    }
    return arr;
  }

  /** Returns a list of the algorithm subtypes available for a type. */
  getAlgorithmSubtypes(type: AlgorithmType): AlgorithmSubtype[] {
    switch (type) {
      case AlgorithmType.appointment:
        return [AlgorithmSubtype.vet, AlgorithmSubtype.nurse];
      case AlgorithmType.speciality:
        return [
          AlgorithmSubtype.dermatologyInitial,
          AlgorithmSubtype.dermatologyFollowUp,
          AlgorithmSubtype.dermatology,
          AlgorithmSubtype.behaviourInitial,
          AlgorithmSubtype.behaviourFollowUp
        ];
      case AlgorithmType.triage:
      case AlgorithmType.video:
        return [AlgorithmSubtype.vet, AlgorithmSubtype.nurse];
      case AlgorithmType.clinicalTrial:
        return [AlgorithmSubtype.skinTrialInitial, AlgorithmSubtype.skinTrialFollowUp];
    }
  }

}
