export interface Question {
    id: number;
    tree_id: number;
    type: string;
    title: string;
    text: string;
    operations: Operation[];
    options: Option[];
    rules: Rule[];
    input_type: string;
    input_unit: string;
    interactive: boolean;
    skippable: boolean;
}
interface Operation {
    id: number;
    on: string;
    do: string;
    source: string;
    field: string;
    to: string;
}
interface Option {
    display_value: string;
    description: string;
    popover: string;
    value: number;
    order: number;
    url: string;
    image_url: string;
    price: number;
}
interface Rule {
    order: number;
    default: boolean;
    conditions: Condition[];
    next: Question;
}

interface Condition {
    variable: string;
    comparator: string;
    value: number;
}

export interface Next {
    id: number;
    comparator: string;
    value: number;
    text: string;
    info: string;
    next: Question;
    order: number;
    url?: string;
    img?: string;
    price?: number;
}

export interface UpdateReturn {
    question: Question;
}

export interface Message {
    message: string;
    data: MessageData;
}

interface MessageData {
    node: Question;
    questionList: Question[];
}
export interface UserSettings {
    id: number;
    full_name: string;
    email: string;
    permissions: string;
}
export interface User {
    id: number;
    full_name: string;
    email: string;
}
interface AccessToken {
    access_token: string;
    expires_in: number;
    scope: string;
    token_type: string;
}
export interface Token {
    user: User;
    access_token: AccessToken;
}
export interface Species {
    species: [string];
}
export interface Instruction {
    instruction: string;
    data: any;
}
export interface TriageInstruction extends Instruction{
    for:string;
}

export interface Algorithm {
    id: number;
    type: Type;
    title: string;
    description: string;
    doc_id: string;
    icon: string;
    species: Species;
    status: string;
    category_path: any;
    draft?: any;
    isDraft?: boolean;
    showingDraft?: boolean;
    loadingDraft?: boolean;
}

interface Type {
    type: AlgorithmType;
    subtype: AlgorithmSubtype;
}

export enum AlgorithmType {
    triage = 'triage',
    video = 'video',
    appointment = 'appointment',
    speciality = 'speciality',
    clinicalTrial = 'clinical-trial'
}
export enum AlgorithmSubtype {
    vet = 'vet',
    nurse = 'nurse',
    dermatologyInitial = 'dermatology-initial',
    dermatologyFollowUp = 'dermatology-follow-up',
    dermatology = 'dermatology50',
    behaviourInitial = 'behaviour-initial',
    behaviourFollowUp = 'behaviour-follow-up',

    skinTrialInitial = 'afi-skin-trial-initial',
    skinTrialFollowUp = 'afi-skin-trial-follow-up'
}

export interface Deleted {
    status: string;
}
export interface TriageResponse {
    next: Question;
    tree: Algorithm;
}
// @ToDo: these need to be separate files
export interface ShippingLabelCSV {
  FullName: string;
  AddressLine1: string;
  City: string;
  Postcode: string;
  PackageSize: string;
  ServiceCode: string;
  Weight: number;
  AvailablePackageSizes: Array<string>;
  ErrorDetails?: any;
}
