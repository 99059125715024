import { Component, Input, OnInit } from '@angular/core';

import { AlertService } from '../../../../shared/services/alert.service';
import { AppointmentTypesService } from '../../../../appointment-types/services/appointment-types.service';

@Component({
  selector: 'app-sidebar-appointment-type',
  templateUrl: './sidebar-appointment-type.component.html',
  styleUrls: ['./sidebar-appointment-type.component.css']
})
export class SidebarAppointmentTypeComponent implements OnInit {

  @Input() data: any;
  availableAppointmentTypes: any;

  constructor(private alertService: AlertService,
              private appointmentTypeService: AppointmentTypesService) { }

  ngOnInit(): void {
    this.getAppointmentTypes();
  }

  getAppointmentTypes() {
    this.appointmentTypeService.getAppointmentTypes().subscribe(data => {
      this.availableAppointmentTypes = data;
    }, data => {
      this.alertService.error(data.error.cause ? data.error.cause : 'Problem getting appointment types');
    });
  }

  handleAppointmentTypeSelect(event) {
    this.data.data.metadata.appointment_type_id = [JSON.stringify(event.id)];
    this.data.data.metadata.appointment_type_display = [event.display_name];
  }

  clearAppointmentType() {
    delete this.data.data.metadata.appointment_type_id;
    delete this.data.data.metadata.appointment_type_display;
  }

}
