export enum ProductLegalCategoryEnum {
  pomV = 'pom-v',
  pomSchedule = 'pom-schedule',
  pomHuman = 'pom-human',
  pharmacy = 'pharmacy',
  trial = 'clinical-trial',
  nfaVps = 'nfa-vps',
  avmGsl = 'avm-gsl',
  saes = 'saes ',
  NA = 'n-a'
}
