import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray  } from '@angular/cdk/drag-drop';
import { Next } from '../../../../shared/models/interface';
import { SidebarComponentInterface } from '../sidebar-item.component';
import { NodeStyleService } from '../../../../shared/services/node-style.service';

@Component({
  selector: 'app-sidebar-multiple-choice',
  templateUrl: './sidebar-multiple-choice.component.html',
  styleUrls: ['./sidebar-multiple-choice.component.css']
})
export class SidebarMultipleChoiceComponent implements OnInit, SidebarComponentInterface {
  alertText = {
    text: '',
    type: ''
  };
  @Input() public data: any;
  styleOptions: Array<string>;
  constructor(private nodeStyleService: NodeStyleService) {  }

  ngOnInit() {
    this.styleOptions = this.nodeStyleService.getStyleOptions();
  }

  drop(event: CdkDragDrop<any[]>, node) {

    node.data.options[event.currentIndex].order = event.previousIndex;
    node.data.options[event.previousIndex].order = event.currentIndex;
    moveItemInArray(node.data.options, event.previousIndex, event.currentIndex);
    // this.alertDrag(null, {x: 0, y: 0});
  }

  alertDrag(item, event) {
  //     if (this.itemDragging === item) {

  //     } else {
  //       this.itemDragging = item;
  //       this.dataService.alertDragRequest(item);
  //     }
  }

  copyOption(o: any): any {
    return JSON.parse(JSON.stringify(o));
  }
  // (cdkDragMoved)="alertDrag(rule, $event)"
  addOption(node) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      const option = {
        display_value: '',
        description: '',
        popover: '',
        value: 0,
        order: 0,
        url: '',
        image_url: '',
        price: 0,
        style: 'primary',
      };
      if (node.data.options === null || node.data.options === undefined) {
        node.data.options = [];
      } else {
        node.data.options.push(option);
      }
    }
  }

  removeOption(selected: Next, node) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      for (let i = 0; i < node.data.options.length; i++) {
        if (selected === node.data.options[i]) {
          node.data.options.splice(i, 1);
        }
      }
    }
  }

}
