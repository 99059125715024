import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MediaDetailsInterface } from '../../interfaces/media-details.interface';

@Component({
  selector: 'app-media-manager-input',
  templateUrl: './media-manager-input.component.html',
  styleUrls: ['./media-manager-input.component.css']
})
export class MediaManagerInputComponent implements OnInit {

  @Input() mediaType: string;
  @Input() mediaUrl: string;
  @Input() disabled: boolean;
  @Output() updateMediaUrl: EventEmitter<MediaDetailsInterface> = new EventEmitter<MediaDetailsInterface>();
  @Output() clearMediaUrl: EventEmitter<any> = new EventEmitter<any>();
  @Output() typeError: EventEmitter<any> = new EventEmitter<any>();
  availableMediaTypes: Array<string>;
  supportedImageTypes: Array<string>;
  supportedVideoTypes: Array<string>;
  supportedDocTypes: Array<string>;

  constructor() { }

  ngOnInit(): void {
    this.setupMediaConfig();
  }

  setupMediaConfig() {
    this.supportedImageTypes = ['.png', '.gif', '.jpg', 'jpeg'];
    this.supportedVideoTypes = ['.mp4'];
    this.supportedDocTypes = ['.pdf'];
    this.availableMediaTypes = ['graphic', 'video', 'document'];
  }

  uploadFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      const fileType = event.target.files[0].name.slice(-4);
      let validFileType = false;
      switch (this.mediaType) {
        case 'graphic':
          validFileType = this.supportedImageTypes.includes(fileType);
          break;
        case 'video':
          validFileType = this.supportedVideoTypes.includes(fileType);
          break;
        case 'document':
          validFileType = this.supportedDocTypes.includes(fileType);
          break;
      }
      if (validFileType) {
        this.updateMediaUrl.emit({file: event.target.files[0], type: this.mediaType});
      } else {
        this.typeError.emit();
      }
    }
  }

  clearFile() {
    this.clearMediaUrl.emit();
  }

}
