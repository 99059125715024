import { Pipe, PipeTransform } from '@angular/core';
import {every, isString} from 'lodash';

@Pipe({
  name: 'tooltipListFormat'
})
export class TooltipListFormatPipe implements PipeTransform {

  transform(value: Array<string>): any {
    if (!every(value, (x) => isString(x))) {
      return value;
    }

    let formatted = '';
    return value.map((x) => {
      formatted = `${formatted}- ${x}&#13;`;
    });
  }

}
