import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-media-manager-preview',
  templateUrl: './media-manager-preview.component.html',
  styleUrls: ['./media-manager-preview.component.css']
})
export class MediaManagerPreviewComponent implements OnInit {

  @Input() mediaType: string;
  @Input() mediaUrl: string;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openPreviewModal(template) {
    // Open documents in new browser tab or open modal to display image/video
    if (this.mediaType === 'document') {
      // @ToDo: Not sure current best practice with window.open (needs review)
      window.open(this.mediaUrl, '_blank');
    } else {
      this.dialog.open(template, {maxHeight: '80vh', maxWidth: '80vw'});
    }
  }

}
