import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shippingServiceCode'
})
export class ShippingServiceCodePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let fullName = '';
    switch (value) {
      case 'SD1':
        fullName = 'Special Delivery Guaranteed by 1pm - £500';
        break;
      case 'BPL1':
        fullName = 'Royal Mail 1st Class';
        break;
      case 'BPL2':
        fullName = 'Royal Mail 2nd Class';
        break;
      case 'CRL24':
        fullName = 'Royal Mail 24';
        break;
      case 'CRL48':
        fullName = 'Royal Mail 48';
        break;
      default:
        fullName = value;
        break;
    }
    return fullName;
  }

}
