import {EventEmitter, Injectable} from '@angular/core';
import * as _ from 'lodash';
import { Instruction, TriageInstruction } from '../models/interface';
import { TriageViewModel } from '../../triage/view-models/triage.view-model';

@Injectable()
export class DataService {
  private publishNodeUpdate: EventEmitter<any> = new EventEmitter();
  private updateCancel: EventEmitter<any> = new EventEmitter();
  private updateTriage: EventEmitter<any> = new EventEmitter();
  private publishSidebarClose: EventEmitter<any> = new EventEmitter();
  private profileInstruction: EventEmitter<any> = new EventEmitter();
  private triageInstruction: EventEmitter<any> = new EventEmitter();
  // private updateDrag: EventEmitter<any> = new EventEmitter();
  private updatedTree: EventEmitter<any> = new EventEmitter();
  private profileData: EventEmitter<any> = new EventEmitter();
  private updateForm: EventEmitter<any> = new EventEmitter();
  private refreshTriageList: EventEmitter<any> = new EventEmitter();
  private userSettingInstruction: EventEmitter<any> = new EventEmitter();
  metaData: any;
  private publishTriage: EventEmitter<any> = new EventEmitter();
  tabNumber: number;
  userSettingsInstruction:  EventEmitter<any> = new EventEmitter();
  staffContainerInstruction:  EventEmitter<any> = new EventEmitter();
  permissionInstruction: EventEmitter<any> = new EventEmitter();
  pricingInstruction: EventEmitter<any> = new EventEmitter();

  constructor(public triageViewModel: TriageViewModel) { }

  publishMessage(message: string, data: any) {
    this.publishNodeUpdate.emit({message: message, data: data});
  }
  setTabNumber(number) {
    console.log('tab number is:', number);
    this.tabNumber = number;
  }
  getTabNumber() {
    return this.tabNumber;
  }
  alertTriageRequest(link: any) {
    this.triageViewModel.tree = link;
    this.updateTriage.emit(link);
  }

  alertCancel(link: string) {
    this.updateCancel.emit(link);
  }
  formListener(data, dataList) {
    this.updateForm.emit({data: data, dataList: dataList});
  }

  alertUpdateTree(data) {
    this.updatedTree.emit(data);
  }
  updateTree(): EventEmitter<any> {
    return this.updatedTree;
  }
  alertSidebarClose() {
    this.publishSidebarClose.emit('close');
  }
  updateTriageList() {
    this.refreshTriageList.emit(true);
  }
  alertTriageRefresh(): EventEmitter<any> {
    return this.refreshTriageList;
  }
  alertFormListener(): EventEmitter<any> {
    return this.updateForm;
  }
  alertCancelEvent(): EventEmitter<any> {
    return this.updateCancel;
  }
  alertTriageEvent(): EventEmitter<any> {
    return this.updateTriage;
  }
  getSidebarEmmiter(): EventEmitter<any> {
    return this.publishSidebarClose;
  }
  getMessageEmmiter(): EventEmitter<any> {
    return this.publishNodeUpdate;
  }
  updateMeta(meta) {
    this.metaData = meta;
  }
  returnTriage(): EventEmitter<any> {
    return this.publishTriage;
  }
  profileDataListener() {
    return this.profileData;
  }
  emitUserSettingsInstruction(instruction: Instruction) {
    console.log('emiting instruction', instruction);
    this.userSettingsInstruction.emit(instruction);
  }
  userSettingsInstructionListener(): EventEmitter<any> {
    return this.userSettingsInstruction;
  }
  emitStaffContainerInstruction(instruction: Instruction) {
    this.staffContainerInstruction.emit(instruction);
  }
  listenForStaffContainerInstruction(): EventEmitter<any> {
    return this.staffContainerInstruction;
  }
  emitProfileInstruction(instruction: Instruction) {
    this.profileInstruction.emit(instruction);
  }
  listenForProfileInstruction(): EventEmitter<any> {
    return this.profileInstruction;
  }
  emitPermissionsInstruction(instruction: Instruction) {
    console.log('emitting instruction for permissions component: ', instruction);
    this.permissionInstruction.emit(instruction);
  }
  listenForPermissionsInstruction(): EventEmitter<any> {
    return this.permissionInstruction;
  }
  emitPricingInstruction(instruction: Instruction) {
    console.log('emitting instruction for permissions component: ', instruction);
    this.pricingInstruction.emit(instruction);
  }
  listenForPricingInstruction(): EventEmitter<any> {
    return this.pricingInstruction;
  }
  emitProfileData(data) {
    this.profileData.emit(data);
  }
  listenForInstruction(): EventEmitter<any> {
    return this.triageInstruction;
  }
  sendTriageInstruction(instruction: TriageInstruction) {
    this.triageInstruction.emit(instruction);
  }
  checkIfObjectsAreEqual(objectA, objectB) {
    return _.isEqual(objectA, objectB);
  }
  checkIfIncludesInArray(array, object) {
    return _.includes(array, object);
  }
  removeItemWithParent(array) {
    return _.reject(array, el => {
      return el.category_path.length !== 0;
    });
  }
  removeCategoryFromTriage(array, category_id) {
    return _.reject(array, element => {
      return element === category_id;
    });
  }
  removeItemFromArray(array, object) {
    console.log('removing item from array: ', array, ', object looks like this: ', object);
    return _.reject(array, el => {
      return el.id === object.id;
    });
  }
}
