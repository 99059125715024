import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor() { }

  getNotificationDataTypes() {
    return [
      {
        label: 'Title',
        type: 'title',
        content: 'Placeholder title'
      },
      {
        label: 'Body text',
        type: 'text',
        content: 'Placeholder body text'
      },
      {
        label: 'Image',
        type: 'image',
        content: './assets/placeholders/image-placeholder.png'
      },
      {
        label: 'Video',
        type: 'video',
        content: './assets/placeholders/video-placeholder.png'
      },
      {
        label: 'Button',
        type: 'call-to-action',
        content: {
          label: 'Placeholder button text',
          type: 'nurse-call',
        }
      },
      {
        label: 'Product',
        type: 'product',
        content: {
          id: null,
          name: 'Placeholder product',
          description: 'Some placeholder product description',
          images: [
            {
              image_url: './assets/placeholders/image-placeholder.png'
            }
          ],
          price: {
            price: 0
          }
        },
        custom_summary: null
      },
      {
        label: 'Delimiter',
        type: 'delimiter',
        content: ''
      },
      {
        label: 'Carousel',
        type: 'carousel',
        content: './assets/placeholders/carousel-placeholder.png'
      },
    ];
  }

  getButtonOptions() {
    return [
      {
        label: 'Nurse consultation',
        value: 'nurse-consultation'
      },
      {
        label: 'Free Nurse consultation',
        value: 'free-nurse-consultation'
      },
      {
        label: 'Vet consultation',
        value: 'vet-consultation'
      },
      {
        label: 'Free Vet consultation',
        value: 'free-vet-consultation'
      },
      {
        label: 'Emergency',
        value: 'emergency'
      },
      {
        label: 'Non Emergency (Vet)',
        value: 'non-emergency-vet'
      },
      {
        label: 'Non Emergency (Nurse)',
        value: 'non-emergency-nurse'
      },
      {
        label: 'Symptom checker',
        value: 'symptom-checker'
      },
      {
        label: 'Buy products',
        value: 'buy-products'
      },
      {
        label: 'External link',
        value: 'external-link'
      },
      {
        label: 'Dismiss',
        value: 'dismiss'
      }
    ];
  }
}
