import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-notification-builder-options',
  templateUrl: './notification-builder-options.component.html',
  styleUrls: ['./notification-builder-options.component.scss']
})
export class NotificationBuilderOptionsComponent implements OnInit {

  @Input() notificationDataTypes: Array<any>;

  constructor() { }

  ngOnInit() {
  }

}
