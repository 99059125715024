<div *ngIf="data.data" class='container margin-bottom-20'>
  <div>
    <label for="inputType">Input Type</label>
    <select
      class="form-control margin-bottom-10"
      [(ngModel)]="data.data.input_type"
      [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
      (change)="setupInputUnitScreen()">
      <option *ngFor="let input of input_types" [ngValue]="input.value">{{input.display_value}}</option>
    </select>
  </div>
  <div>
      <label for="inputUnit">Input Unit</label>
      <input
        class="form-control margin-bottom-10"
        type="text"
        [(ngModel)]="data.data.input_unit"
        [disabled]="(data.data.input_type === 'weight' || data.data.input_type === 'age') &&
        (data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused')">
    </div>
</div>
