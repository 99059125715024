import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sidebar-decision',
  templateUrl: './sidebar-decision.component.html',
  styleUrls: ['./sidebar-decision.component.css']
})
export class SidebarDecisionComponent implements OnInit {
  @Input() data: any;
  operators = ['>', '<', '==', '>=', '<=', '!='];
  comparateTypes = ['variable', 'value'];
  conditionTypes = ['formula', 'variable'];
  aList = [];
  activeFormula: any;
  conditionEditing: any;
  number: any;
  selectedVariable: any;
  temporaryFormula: any;
  selectedCondition: {
    rule: number,
    condition: number
  };
  constructor(private dialog: MatDialog) {
    this.activeFormula = [];
    this.temporaryFormula = [];
    this.selectedCondition = {
      rule: 0,
      condition: 0
    };
  }

  ngOnInit() {
    this.sortData(null);
    if (this.data.data.rules) {
      for (let i = 0; i < this.data.data.rules.length; i++) {
        if (this.data.data.rules[i].conditions) {
          for (let j = 0; j < this.data.data.rules[i].conditions.length; j++) {
            this.sortData(this.data.data.rules[i].conditions[j]);
          }
        }
      }
    }
  }

  sortData(variableName) {
    const list = this.data.dataList.data.decisionList;
    list.forEach(element => {
      if (element.operations && element.operations !== []) {
        element.operations.forEach(operation => {
          if (!variableName) {
            if (operation.do === 'save-variable') {
              const item = {
                variable: operation.to,
                type: element.type
              };
              this.aList.push(item);
            }
          } else {
            if (variableName.variable === operation.to) {
              variableName.list = element.options;
            }
          }
        });
      }
    });
  }

  update(update) {
    this.sortData(update);

  }
  addRule() {
    const rule = {
      conditions: [],
      order: 0,
      is_default: false,
      next: {
        options: [],
        rules: [],
        text: '',
        title: '',
        type: {type: 'information', subtype: 'text'},
        input_type: '',
        input_unit: '',
        interactive: false
      },
    };
    if (!this.data.data.rules) {
      this.data.data.rules = [];
    }
    if (this.data.data.rules.length <= 1) {
      rule.is_default = true;
    }
    this.data.data.rules.push(rule);
  }

  addCondition(rule) {
    const condition = {
        condition_type: '',
        variable: '',
        formula: '',
        value: 0,
        comparate_type: '',
        comparing_variable: '',
        comparator: '==',
    };
    if (rule.conditions === null || rule.conditions === undefined) {
      rule.conditions = [];
    }
    rule.conditions.push(condition);
  }
  addVariable(condition) {
    condition.variable = [condition.variable];
    condition.variable.push('');
  }

  removeRule(selected, node) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      for (let i = 0; i < node.rules.length; i++) {
        if (selected === node.rules[i]) {
          node.rules.splice(i, 1);
        }
      }
    }
  }


  removeCondition(selected, index, rule) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      rule.conditions.splice(index, 1);
    }
  }

  dropFormula(direction, index) {
    const selected = this.activeFormula[index];
    let itemSwap;
    if (direction === 'left') {
      if (index !== 0) {
        itemSwap = this.activeFormula[index - 1];
        this.activeFormula[index] = itemSwap;
        this.activeFormula[index - 1] = selected;
      }
    } else if (direction === 'right') {
      if (index !== this.activeFormula.length - 1) {
        itemSwap = this.activeFormula[index + 1];
        this.activeFormula[index] = itemSwap;
        this.activeFormula[index + 1] = selected;
      }
    }
  }

  drop(event: CdkDragDrop<any[]>, node) {
    node.data.rules[event.currentIndex].order = event.previousIndex;
    node.data.rules[event.previousIndex].order = event.currentIndex;
    moveItemInArray(node.data.rules, event.previousIndex, event.currentIndex);
  }

  copyOption(o: any): any {
    return JSON.parse(JSON.stringify(o));
  }

  editFormula(rule: number, condition: number, template) {
    this.selectedCondition = {
      rule: rule,
      condition: condition
    };
    this.activeFormula = this.changeStringToArray(this.copyOption(this.data.data.rules[rule].conditions[condition].formula));
    this.dialog.open(template, {disableClose: true});
  }
  changeArrayToString(array) {
    return array.join('');
  }

  changeStringToArray(string) {
    const characters = ['(', ')', '+', '/', '*', '-'];
    const loopNumber = string.length;
    let temporaryVariableCreation = '';
    let temporaryNumber = '';
    const arrayToReturn = [];
    for (let i = 0; i < loopNumber; i++) {
      const character = string[i];
      if (!isNaN(character) || character === '.') {
        temporaryNumber = temporaryNumber + character;
      } else {
        if (temporaryNumber !== '') {
          arrayToReturn.push(temporaryNumber);
          temporaryNumber = '';
        }
        if (characters.includes(character)) {
          arrayToReturn.push(character);
        }
        if (character === '{') {
          temporaryVariableCreation = character;
        } else if (character === '}') {
          temporaryVariableCreation = temporaryVariableCreation + character;
          arrayToReturn.push(temporaryVariableCreation);
        } else {
          temporaryVariableCreation = temporaryVariableCreation + character;
        }
      }
    }
    return arrayToReturn;
  }

  openFormulaModal(rule, condition, template) {
    this.temporaryFormula = this.changeStringToArray(this.data.data.rules[rule].conditions[condition].formula);
    this.dialog.open(template);
  }
  completeFormula() {
    const arrayToString = this.changeArrayToString(this.copyOption(this.activeFormula));
    this.data.data.rules[this.selectedCondition.rule].conditions[this.selectedCondition.condition].formula = arrayToString;
    this.dialog.closeAll();
  }
  cancelFormula() {
    this.activeFormula = this.changeStringToArray(this.copyOption(this.data.data.rules[this.selectedCondition.rule].conditions[this.selectedCondition.condition].formula));
    this.dialog.closeAll();
  }
  updateConditionType(condition) {
    condition.variable = '';
    condition.formula = '';
  }
  addToFormula(value, type ) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      if (type === 'variable') {
        this.activeFormula.push(`{${value}}`);
      } else {
        this.activeFormula.push(value);
      }
    }
  }
  removeFormulaElement(index) {
    if (this.data.selectedAlgorithm.status !== 'published' && this.data.selectedAlgorithm.status !== 'paused') {
      this.activeFormula.splice(index, 1);
    }
  }
}
