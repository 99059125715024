<div *ngIf="data.data" class='container margin-bottom-20'>
  <label for="options">Options</label>
  <div
    class="example-list margin-bottom-10">
    <div
      *ngFor='let child of data.data.options; let i = index'
      class="example-box">
          <span *ngIf="child">
              <div class="container">
                    <div>
                        <label for="option">Display Value</label>
                        <input
                          [(ngModel)]="child.display_value"
                          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                          class="form-control width-100"
                          name="option"
                          type="text">
                    </div>
                    <div>
                      <label for="description">Description</label>
                      <textarea
                        [(ngModel)]="child.description"
                        [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                        class="form-control width-100"
                        name="description"
                        type="text"></textarea>
                    </div>
                    <div>
                      <label for="value">Style</label>
                      <select
                        [(ngModel)]="child.style"
                        [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                        class="form-control width-100">
                        <option *ngFor="let option of styleOptions" [value]="option">{{option}}</option>
                      </select>
                      <div *ngIf="!child.media_url">
                        <label for="media_url">Image URL</label>
                        <input
                          [(ngModel)]="child.media_url"
                          class="form-control"
                          disabled
                          placeholder="Image" type="text"/>
                        <input #fileInput (change)="fileChangeEvent($event, child)" class="width-100" hidden
                               type="file"/>
                        <button (click)="fileInput.click()" class="btn btn-primary btn-block margin-bottom-20">Choose file</button>
                    </div>
                    <div *ngIf="child.media_url" class="image-holder">
                      <span (click)="removeImage(child)" class="close-btn" title="Remove image">
                        <i class="fas fa-times-circle"></i>
                      </span>
                      <img [src]="child.media_url"/>
                    </div>
                    </div>
                </div>
          </span>
    </div>
    <div *ngIf="!data.data.options || data.data.options.length === 0" class="padding-10">
      Options missing, please recreate node
    </div>
  </div>
</div>
