import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {

  transform(value: any, args): any {
    let returnString = '';
    returnString = value;

    if (value.length > args) {
      returnString = returnString.slice(0, args);
      returnString = returnString + '...';
    }
    return returnString;
  }

}
