import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import { AuthGuard } from './shared/guards/auth-guard';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'triage',
        loadChildren: () => import('./triage/triage.module').then(m => m.TriageModule),
        canActivate: [AuthGuard]
    },
    {
      path: 'practices',
      loadChildren: () => import('./practices/practices.module').then(m => m.PracticesModule),
      canActivate: [AuthGuard]
    },
    {
        path: 'products',
        loadChildren: () => import('./products/products.module').then(m => m.ProductModule),
        canActivate: [AuthGuard]
    },
    {
      path: 'triage-products',
      loadChildren: () => import('./triage-products/triage-products.module').then(m => m.TriageProductsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'staff',
      loadChildren: () => import('./staff-manager/staff-manager.module').then(m => m.StaffManagerModule),
      canActivate: [AuthGuard]
    },
    {
        path: 'permissions',
        loadChildren: () => import('./permissions/permissions.module').then(m => m.PermissionsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'promo-codes',
        loadChildren: () => import('./promo-code/promo-code.module').then(m => m.PromoCodeModule),
        canActivate: [AuthGuard]
    },
    {
      path: 'price-policies',
      loadChildren: () => import('./price-policies/price-policies.module').then(m => m.PricePoliciesModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'refunds',
      loadChildren: () => import('./refunds/refunds.module').then(m => m.RefundsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'email-verification',
      loadChildren: () => import('./email-verification/email-verification.module').then(m => m.EmailVerificationModule),
      canActivate: [AuthGuard]
    },
    /*{
        path: 'pharmacy',
        loadChildren: () => import('./pharmacy/pharmacy.module').then(m => m.PharmacyModule),
        canActivate: [AuthGuard]
    },*/
    {
        path: 'lab-tests',
        loadChildren: () => import('./lab-tests/lab-tests.module').then(m => m.LabTestsModule),
        canActivate: [AuthGuard]
    },
    {
      path: 'appointment-types',
      loadChildren: () => import('./appointment-types/appointment-types.module').then(m => m.AppointmentTypesModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'workflows',
      loadChildren: () => import('./workflows/workflows.module').then(m => m.WorkflowsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'services',
      loadChildren: () => import('./services-manager/services-manager.module').then(m => m.ServicesManagerModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'extended-data',
      loadChildren: () => import('./extended-data/extended-data.module').then(m => m.ExtendedDataModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'rules-manager',
      loadChildren: () => import('./rules-manager/rules-manager.module').then(m => m.RulesManagerModule),
      canActivate: [AuthGuard]
    },
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full'
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
