import { Component, Input, OnInit } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { TriageService } from '../../../../shared/services/triage-service.service';

@Component({
  selector: 'app-sidebar-images',
  templateUrl: './sidebar-images.component.html',
  styleUrls: ['./sidebar-images.component.css']
})
export class SidebarImagesComponent implements OnInit {

  @Input() public data: any;
  images: Array<any> = [];
  isLoading: boolean;

  constructor(private triageService: TriageService) { }

  ngOnInit() {
    if (this.data.data.options) {
      this.images = [...this.data.data.options];
    }
  }

  removeImage(index) {
    this.images.splice(index, 1);
    this.data.data.options.splice(index, 1);
  }

  dropImage(event) {
    moveItemInArray(this.data.data.options, event.previousIndex, event.currentIndex);
    this.images = [...this.data.data.options];
    this.reorderImages();
  }

  reorderImages() {
    this.data.data.options.forEach((item, index) => {
      item.order = index;
    });
    this.images = [...this.data.data.options];
  }

  addImages(event) {
    const uploadedNewFile = event.target.files[0];
    const formData = new FormData();
    if (uploadedNewFile) {
      this.isLoading = true;
      formData.append('image', uploadedNewFile, uploadedNewFile.name);
      this.triageService.uploadImage(formData).subscribe((data: any) => {
        if (!this.data.data.options) {
          this.data.data.options = [];
        }
        const imageObject = {
          order: this.images.length,
          description: '',
          media_url: 'https://storage.googleapis.com/assets.vet-ai.com/' + data.filenames[0]
        };
        this.data.data.options.push(imageObject);
        this.images.push(imageObject);
        this.isLoading = false;
      }, data => {
        this.isLoading = false;
      });
    }
  }
}
