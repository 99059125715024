import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ProductImageInterface} from '../../interfaces/product-image.interface';
import {ImageManagerService} from '../../services/image-manager.service';
import {AlertService} from '../../services/alert.service';
import {Subscription} from 'rxjs';
import {every, first, isArray, isString} from 'lodash';

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.css']
})
export class ImageSelectorComponent implements OnInit {

  @Input() label: string;
  @Input() images: Array<ProductImageInterface> | string;
  @Input() isDisabled: boolean;
  @Input() selectorType: 'gallery' | 'single' | 'single-square';
  removeHovered: boolean;
  @Output() selectionChanges: EventEmitter<Array<ProductImageInterface> | string> = new EventEmitter<Array<ProductImageInterface> | string>();
  subscriptions: Subscription = new Subscription();

  constructor(
    private readonly imageManagerService: ImageManagerService,
    private readonly alertService: AlertService
  ) { }

  ngOnInit() {
  }

  uploadImage(event: any): void {
    const uploadedNewFile = event.target.files[0];
    const formData = new FormData();

    switch (this.selectorType) {
      case 'gallery': {
        if (uploadedNewFile) {
          formData.append('image', uploadedNewFile, uploadedNewFile.name);
          this.subscriptions.add(this.imageManagerService.uploadImage(formData).subscribe((data: any) => {
            if (data.image_urls) {
              if (!this.images || !this.isProductImageArray(this.images)) {
                this.images = [];
              }
              this.images.push({image_url: data.image_urls[0]});
              this.selectionChanges.emit(this.images);
            }
          }, data => {
            this.alertService.error(data.error.cause ? data.error.cause : 'Problem uploading image');
          }));
        }
        break;
      }
      default : {
        if (uploadedNewFile) {
          formData.append('image', uploadedNewFile, uploadedNewFile.name);
          this.subscriptions.add(this.imageManagerService.uploadImage(formData).subscribe((data: any) => {
            if (data.image_urls) {
              this.images = first(data.image_urls);
              this.selectionChanges.emit(this.images);
            }
          }, data => {
            this.alertService.error(data.error.cause ? data.error.cause : 'Problem uploading image');
          }));
          break;
        }
      }
    }
  }

  sortImages(event: CdkDragDrop<any, any>): void {
    if (this.isProductImageArray(this.images)) {
      moveItemInArray(this.images, event.previousIndex, event.currentIndex);
      this.selectionChanges.emit(this.images);
    }
  }

  removeImage(image: any): void {
    switch (this.selectorType) {
      case 'gallery': {
        if (this.isProductImageArray(this.images) && this.isProductImageInterface(image)) {
          const removeIndex = this.images.indexOf(image);
          this.images.splice(removeIndex, 1);
        }
        break;
      }
      default: {
        this.images = undefined;
        break;
      }
    }
    this.selectionChanges.emit(this.images);
  }

  isProductImageArray(images: Array<ProductImageInterface> | string): images is Array<ProductImageInterface> {
    return isArray(images) && every(images, image => this.isProductImageInterface(image));
  }

  isProductImageInterface(image: ProductImageInterface | string): image is ProductImageInterface {
    return !isString(image) && image.hasOwnProperty('image_url') && isString(image.image_url);
  }

  onRemoveMouseOver() {
    this.removeHovered = true;
  }

  onRemoveMouseOut() {
    this.removeHovered = false;
  }
}
