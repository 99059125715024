<section class="container">
  <div *ngIf="images.length > 0"
       id="drop-list"
       class="image-list"
       cdkDropList
       [cdkDropListDisabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
       (cdkDropListDropped)="dropImage($event)">
    <div *ngFor="let image of data.data.options; let i = index;" cdkDrag>
      <hr *ngIf="i > 0" />
      <button
        class="btn btn-block btn-danger remove-btn"
        [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
        (click)="removeImage(i)">Remove image</button>
      <img [src]="image.media_url" />
      <label>Description</label>
      <textarea
        [(ngModel)]="image.description"
        [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
        class="width-100 form-control"></textarea>
    </div>
  </div>
  <div class="loading-image" *ngIf="isLoading">
    <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
    <p>Uploading image...</p>
  </div>
  <input hidden type="file" #fileInput (change)="addImages($event)" />
  <button
    class="btn btn-primary width-100 margin-bottom-10"
    [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
    (click)="fileInput.click()">Add image</button>
</section>
