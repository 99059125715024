import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ProductInterface } from '../../interfaces/product.interface';

@Component({
  selector: 'app-products-table',
  templateUrl: './products-table.component.html',
  styleUrls: ['./products-table.component.css']
})
export class ProductsTableComponent implements OnInit, OnChanges {

  @Input() dataSource: any;
  @Input() displayedColumns: Array<string>;
  @Input() filteredProduct: string;
  @Input() canEditStock: boolean;
  @Input() userWriteAccess: boolean;
  @Input() canSearch = true;
  @Input() productSearchTerm: string;
  @Input() searchByColumn: string;
  @Output() addEditProductDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() addGroupEditProductDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteProductDialog: EventEmitter<ProductInterface> = new EventEmitter<ProductInterface>();
  @Output() toggleStockStatus: EventEmitter<ProductInterface> = new EventEmitter<ProductInterface>();
  @Output() triggerFreshData: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateProductStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleProductStock: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleProductSubscribable: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() { }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.filterTableData(this.searchByColumn);
  }

  ngOnChanges(changes) {
    if (changes) {
      if (changes.productSearchTerm &&
        changes.productSearchTerm.prevValue !== changes.productSearchTerm.currentValue
        && !changes.productSearchTerm.isFirstChange()) {
        this.applyFilter(changes.productSearchTerm.currentValue);
      }
      if (changes.searchByColumn &&
        changes.searchByColumn.prevValue !== changes.searchByColumn.currentValue
        && !changes.searchByColumn.isFirstChange()) {
        this.filterTableData(changes.searchByColumn.currentValue);
        if (this.productSearchTerm) {
          this.applyFilter(this.productSearchTerm);
        }
      }
    }
  }

  openAddEditProductDialog(data?) {
    this.addEditProductDialog.emit(data);
  }

  openAddGroupEditProductDialog(data?) {
    this.addGroupEditProductDialog.emit(data);
  }

  openDeleteProductDialog(row) {
    this.deleteProductDialog.emit(row);
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.sort = this.sort;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterTableData(column) {
    this.dataSource.filterPredicate = (filterData, filterString) => {
      return filterData[column].trim().toLowerCase().indexOf(filterString) !== -1;
    };
  }

  clearSearch() {
    this.productSearchTerm = '';
    this.applyFilter('');
  }

  setProductInStock(product: ProductInterface) {
    if (!product.status ||
      product.status !== 'published') {
      return false;
    }
    this.toggleStockStatus.emit(product);
  }

  setProductInStore(product: ProductInterface) {
    if (!product.status ||
      product.status !== 'published') {
      return false;
    }
    this.toggleProductStock.emit(product);
  }

  setProductSubscribable(product: ProductInterface) {
    if (!product.status ||
      product.status !== 'published') {
      return false;
    }
    this.toggleProductSubscribable.emit(product);
  }

  setProductStatus(product: ProductInterface, status) {
    const productUpdate = {
      product: product,
      status: status
    };
    this.updateProductStatus.emit(productUpdate);
  }
  setProductStatusToggle(product: ProductInterface) {
    if (product.group_id !== null) {
      return false;
    }

    if (!product.status ||
      product.status === 'archived' ||
      product.status === 'published') {
      return false;
    }
    // Set showToggle to false for all then set for one sent through param
    this.dataSource.data.forEach((item: any) => {
      if (item.id !== product.id) {
        item.showToggle = false;
      }
    });
    product.showToggle = !product.showToggle;
  }
  showDraftProducts(product: ProductInterface) {
    if (this.sort.direction === 'asc') {
      this.sort.direction = '';
      this.dataSource.sort = this.sort;
    }
    // Set showingDraft to its opposite
    product.showingDraft = !product.showingDraft;
    // Loop through table data to match id
    this.dataSource.data.forEach((item, index) => {
      if (item.id === product.id) {
        if (product.showingDraft) {
          // Add draft to table data
          product.draft.isDraft = true;
          delete product.draft.draft;
          this.dataSource.data.splice(index + 1, 0, product.draft);
        } else {
          // Remove draft to table data
          this.dataSource.data.splice(index + 1, 1);
        }
      }
    });
    // This is a bit ridiculous but it works
    this.dataSource.data = [...this.dataSource.data];
  }
}
