<div class="floating-menu">
  <p>Change status to:</p>
  <button
    *ngIf="collection.active === false"
    class="btn btn-primary live"
    (click)="setStatus(true)">
    Active
  </button>
  <button
    *ngIf="collection.active === true"
    class="btn btn-primary inactive"
    (click)="setStatus(false)">
    Paused
  </button>
</div>
