import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breedFilter',
  pure: true
})
export class BreedFilterPipe implements PipeTransform {
  transform(value: any[], species?: 'dog' | 'cat'): any {
    if (!(value instanceof Array) || !species || !value || !value.length) {
      return value;
    }
    return value.filter((x) => x.species === species);
  }

}
