import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'currencyPrice'
})
export class CurrencyPipe implements PipeTransform {
    transform(amount: number): string {
        return '£' + (amount / 100).toFixed(2);
    }

}
