<section class="appointment-picker-holder">
  <div class="close-btn" *ngIf="!hideClose" (click)="handleClose()"><mat-icon>close</mat-icon></div>
  <ul class="list-unstyled root-list" *ngIf="appointmentTypes !== 0">
    <ng-template #recursiveList let-searchResults>
      <li *ngFor="let item of searchResults" class="category-item">
        <div (click)="toggleCategory(item)" *ngIf="!isSearching || item.category.shouldShow">
            <span
              *ngIf="!item.category.expanded"
              [class.expanded]="item.category.expanded">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </span>
          <span
            *ngIf="item.category.expanded"
            [class.expanded]="item.category.expanded">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </span>
          <span class="category-label">{{item.category.name | titlecase}}</span>
        </div>
        <div *ngIf="item.children && item.category.expanded">
          <ul *ngIf="item.children.length > 0" class="list-unstyled">
            <ng-container
              *ngTemplateOutlet="recursiveList; context:{ $implicit: item.children }"></ng-container>
          </ul>
        </div>
        <div *ngIf="item.appointment_types && item.category.expanded">
          <ul class="list-unstyled">
            <li
              *ngFor="let type of item.appointment_types"
              class="appointment-type"
              [class.chosen-type]="type.isChosen"
              (click)="handleUpdateType(type)">
              <span *ngIf="isSearching && type.shouldShow && type.isMatch" [outerHTML]="type.display_name + ' Code:' + type.name + ')'"></span>
              <span *ngIf="!isSearching || (type.shouldShow && !type.isMatch)">{{type.display_name}} (Code: {{type.name}})</span>
            </li>
          </ul>
        </div>
      </li>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="recursiveList; context:{ $implicit: appointmentTypes }"></ng-container>
  </ul>
</section>
