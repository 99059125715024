<div *ngIf="data.data" class='container margin-bottom-20'>
    <label for="options">Products</label>
    <div cdkDropList cdkDropListData class="example-list margin-bottom-10" (cdkDropListDropped)="drop($event, data)">
        <div *ngFor='let child of data.data.options; let i = index'  class="example-box" cdkDrag  cdkDragLockAxis="y" (cdkDragMoved)="alertDrag(child, $event)">
          <span *ngIf="child" class="width-100">
              <span class="exitClass">
                  <a (click)='removeOption(child, data)'>&times;</a>
              </span>
              <div class="container-fluid">
                <div>
                    <label for="option">Product Display Name</label>
                    <input
                      type="text"
                      name="option"
                      class="form-control width-100"
                      [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                      [(ngModel)]="child.display_value">
                </div>
                <label for="option">Product</label>
                <select
                  [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                  [(ngModel)]="child.product_id"
                  class="form-control">
                    <option *ngFor="let product of products" [ngValue]="product.id">{{product.id}}): {{product.name}}</option>
                </select>
                <div>
                    <label for="description">Description</label>
                    <textarea
                      name="description"
                      class="form-control width-100"
                      [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                      [(ngModel)]="child.description"></textarea>
                </div>
                <!-- {{child | json}} -->
                <div>
                    <label for="value">Value</label>
                    <input
                      class="form-control"
                      type="number"
                      placeholder="Value"
                      [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
                      [(ngModel)]="child.value"/>
                </div>
            </div>
          </span>
      </div>
      <div *ngIf="!data.data.options || data.data.options.length === 0" class="padding-10">
          Please add a product to the Product Recommendations
      </div>
    </div>
    <div class="text-center margin-bottom-10">
        <button
          type="button"
          class="addOptionBtn"
          [disabled]="data.selectedAlgorithm.status === 'published' || data.selectedAlgorithm.status === 'paused'"
          (click)='addOption(data)'>Add Product +</button>
    </div>
  </div>
