import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../services/alert.service';
import { TriageService } from '../../services/triage-service.service';
import { PermissionsService } from '../../services/permissions.service';
import { AuthGuard } from '../../guards/auth-guard';
import { TriageStatusService } from '../../services/triage-status.service';
import { VersionService } from '../../services/version.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  selected = { link: 'SELECT', tag: 'SELECT' };
  selecting = false;
  returnUrl: string;
  user;
  queue: any;
  currentRoute = '';

  menuItems: Array<any>;
  versionNumber: string;
  isAdminRoute: boolean;

  constructor(private data: DataService,
              private auth: AuthenticationService,
              private alertService: AlertService,
              private router: Router,
              private triageService: TriageService,
              private permissionsService: PermissionsService,
              private authGuard: AuthGuard,
              private triageStatusService: TriageStatusService,
              private versionService: VersionService) { }

  ngOnInit() {
    this.startHeader();
  }

  startHeader() {
    this.versionNumber = this.versionService.getVersionNumber();
    this.currentRoute = window.location.pathname.split('/')[1];
    this.user = JSON.parse(this.auth.getToken());
    this.router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        this.versionService.checkVersionNumber();
        // @ToDo: This line is rather grim, can do better
        this.isAdminRoute = this.checkAdminRoute(data.url.substring(1).split('/')[0]);
        this.user = JSON.parse(this.auth.getToken());
        this.currentRoute = window.location.pathname.split('/')[1];
        this.setupMenu();
      }
    });
    this.setupMenu();
  }

  checkAdminRoute(route) {
    let isAdminRoute;
    switch (route) {
      case 'appointment-types':
      case 'promo-codes':
      case 'permissions':
      case 'email-verification':
      case 'services':
      case 'extended-data':
      case 'rules-manager':
        isAdminRoute = true;
        break;
      default:
        isAdminRoute = false;
        break;
    }
    return isAdminRoute;
  }

  setupMenu() {
    if (this.user) {
      this.menuItems = [];
      this.router.config.forEach(item => {
        Object.entries(this.user.permissions).forEach(userItem => {
          if (userItem[0] === item.path) {
            const route = {
              path: item.path,
              isAdmin: false
            };
            route.isAdmin = this.checkAdminRoute(userItem[0]);
            this.menuItems.push(route);
          }
        });
      });
      if (this.user.user.role === 'super-admin' && this.menuItems.indexOf('permissions') === -1) {
        this.menuItems.push({path: 'permissions', isAdmin: true});
      }
    } else {
      this.menuItems = [];
    }
  }

  logout() {
    this.auth.logout();
    this.user = null;
  }

  goToAdminRoute() {
    let firstAdminRoute = '';
    this.menuItems.forEach(item => {
      if (item.isAdmin && !firstAdminRoute) {
        firstAdminRoute = item.path;
      }
    });
    this.router.navigate([firstAdminRoute]);
  }

  setRoute(route) {
    if (this.authGuard.checkIfCanActivateRoute(route, {url: '/'})) {
      this.router.navigate([route]).then(data => {
        this.currentRoute = route;
        this.triageService.clearCurrentTree();
      });
    } else {
      this.alertService.error('You do not have the appropriate permissions to go to this page!');
    }
  }
}
