import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TableSearchOptionInterface } from '../../interfaces/table-search-option.interface';
import { TableSearchOutputInterface } from '../../interfaces/table-search-output.interface';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.css']
})
export class TableSearchComponent implements OnInit, OnDestroy {
  @Input() searchTerm: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() searchOptions: Array<TableSearchOptionInterface>;
  @Input() selectedOption: any;
  // @ToDo: this is throwing a linting error around the use of 'on' in the prefix (rename)
  @Output() onSearchChange: EventEmitter<TableSearchOutputInterface> = new EventEmitter();

  searchTermSubject: BehaviorSubject<string>;
  subscriptions: Subscription = new Subscription();

  constructor() { }

  ngOnInit(): void {
    this.initSearchChanges();
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }

  initSearchChanges() {
    if (this.searchTermSubject) {
      this.subscriptions?.unsubscribe();
      this.subscriptions = new Subscription();
    }
    this.searchTermSubject = new BehaviorSubject<string>(this.searchTerm);
    this.subscriptions.add(
      this.searchTermSubject.pipe(
        debounceTime(500)
      ).subscribe((newSearchTerm: string) => {
        if (this.searchTerm !== newSearchTerm) {
          this.searchTerm = newSearchTerm;
          this.onParamsChange();
        }
      })
    );
  }

  onSearch(newTerm: string): void {
    this.searchTermSubject.next(newTerm);
  }

  onSearchOptionChange(newSearchOption: string): void {
    if (newSearchOption !== this.selectedOption) {
      this.selectedOption = newSearchOption;
      this.onParamsChange();
    }
  }

  clearSearch(): void {
    this.searchTerm = null;
    this.onParamsChange();
  }

  onParamsChange() {
    const params: TableSearchOutputInterface = {searchTerm: this.searchTerm, searchOption: this.selectedOption};
    this.onSearchChange.emit(params);
  }

}
