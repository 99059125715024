import { Component, OnInit, isDevMode } from '@angular/core';
import { AuthenticationService } from './shared/services/authentication.service';
import { DataService } from './shared/services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  metaData: any;
  constructor(private auth: AuthenticationService,
              private dataService: DataService) {}

  ngOnInit() {
    if (JSON.parse(localStorage.getItem('currentUser'))) {
      this.getMetaData();
    }
  }

  getMetaData() {
    this.auth.getStaffProfiles().subscribe((data: any) => {
      this.dataService.updateMeta(null);
      const token = JSON.parse(localStorage.getItem('currentUser'));
      if (token) {
        data.forEach(element => {
            if (element.user_id === token.user.id) {
                this.dataService.updateMeta(element);
            }
        });
      }

    }, error => {
      console.log(error);
    });
  }

}
